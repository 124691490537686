import React from "react";

function StrengthIndicator({ strength }) {
  const getStrengthColor = () => {
    switch (strength) {
      case 1:
        return "#4556f3";
      case 2:
        return "#4556f3";
      case 3:
        return "#4556f3";
      default:
        return "#4556f3";
    }
  };
  const getStrengthWidth = () => {
    return `${(strength / 3) * 100}%`;
  };

  return (
    <div
      style={{
        height: "2px",
        backgroundColor: "#333741",
        borderRadius: "4px",
        marginTop: "4px",
        overflow: "hidden",
        transition: "background-color 0.3s ease",
      }}
    >
      <div
        style={{
          height: "100%",
          width: getStrengthWidth(),
          backgroundColor: getStrengthColor(),
          transition: "width 0.3s ease",
        }}
      />
    </div>
  );
}

export default StrengthIndicator;

import React, { useState } from "react";
import "./ContactPage.scss";
import Contact from "../../components/contact/Contact";
import { Link } from "react-router-dom";
import PrimaryButton from "../../common/primaryButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as userActions from "../../store/actions/user";
import { toast } from "react-toastify";

const validationSchema = Yup.object({
  firstName: Yup.string().required("First name address is required"),
  lastName: Yup.string().required("Last name address is required"),
  description: Yup.string().required("Description address is required"),
  email: Yup.string()
    .email("Email address is invalid")
    .required("Email address is required"),
});

const ContactPage = ({ sendContactForm }) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [fillForm, setFillForm] = useState(false);
  const contactFormick = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      description: "",
    },
    validationSchema,
    onSubmit: (values) => {
      setBtnLoading(true);
      const payload = {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        message: values.description,
      };
      sendContactForm(payload)
        .then((res) => {
          setBtnLoading(false);
          setFillForm(true);
          toast.success(res?.data?.message, { theme: "colored" });
          contactFormick.resetForm();
        })
        .catch((err) => {
          toast.error(err?.res?.data?.message, { theme: "colored" });
          setBtnLoading(false);
        });
    },
  });
  return (
    <>
      <section className="section contact-page-section">
        <div className="content">
          <span>Contact us</span>
          <h2>Get in touch</h2>
          <p>We'd love to hear from you. Please fill out this form.</p>
          <form action="" onSubmit={contactFormick.handleSubmit}>
            <div className="row">
              <div className="input">
                <label htmlFor="firstName">First name*</label>
                <input
                  id="firstName"
                  type="text"
                  placeholder="First name"
                  className={`${
                    contactFormick.touched.firstName &&
                    contactFormick.errors.firstName &&
                    "error-input"
                  }`}
                  value={contactFormick.values.firstName}
                  onChange={contactFormick.handleChange}
                  onBlur={contactFormick.handleBlur}
                />
                {contactFormick.touched.firstName &&
                  contactFormick.errors.firstName && (
                    <span className="error">
                      {contactFormick.errors.firstName}
                    </span>
                  )}
              </div>
              <div className="input">
                <label htmlFor="lastName">Last name*</label>
                <input
                  id="lastName"
                  type="text"
                  placeholder="Last name"
                  className={`${
                    contactFormick.touched.lastName &&
                    contactFormick.errors.lastName &&
                    "error-input"
                  }`}
                  value={contactFormick.values.lastName}
                  onChange={contactFormick.handleChange}
                  onBlur={contactFormick.handleBlur}
                />
                {contactFormick.touched.lastName &&
                  contactFormick.errors.lastName && (
                    <span className="error">
                      {contactFormick.errors.lastName}
                    </span>
                  )}
              </div>
            </div>
            <div className="row">
              <div className="input">
                <label htmlFor="email">Email*</label>
                <input
                  id="email"
                  type="email"
                  placeholder="you@company.com"
                  className={`${
                    contactFormick.touched.email &&
                    contactFormick.errors.email &&
                    "error-input"
                  }`}
                  value={contactFormick.values.email}
                  onChange={contactFormick.handleChange}
                  onBlur={contactFormick.handleBlur}
                />
                {contactFormick.touched.email &&
                  contactFormick.errors.email && (
                    <span className="error">{contactFormick.errors.email}</span>
                  )}
              </div>
            </div>
            <div className="row">
              <div className="input message">
                <label htmlFor="message">Message*</label>
                <textarea
                  name="description"
                  id="message"
                  placeholder="Leave us a message..."
                  className={`${
                    contactFormick.touched.description &&
                    contactFormick.errors.description &&
                    "error-input"
                  }`}
                  value={contactFormick.values.description}
                  onChange={contactFormick.handleChange}
                  onBlur={contactFormick.handleBlur}
                />
                {contactFormick.touched.description &&
                  contactFormick.errors.description && (
                    <span className="error">
                      {contactFormick.errors.description}
                    </span>
                  )}
              </div>
            </div>
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                You agree to our friendly{" "}
                <Link to="/privacy-policy">privacy policy</Link>.
              </label>
            </div>
            <PrimaryButton
              isLoading={btnLoading}
              type={"submit"}
              primaryBtnText="Send message"
            />
          </form>
        </div>
      </section>
      <Contact noMessage />
    </>
  );
};

export default connect(null, userActions)(ContactPage);

import React from "react";
import "./ContactCard.scss";
import { Link } from "react-router-dom";

const ContactCard = ({
  icon,
  title,
  desc,
  linkText,
  linkUrl,
  demo,
  noMessage,
  aos,
  handleClick,
}) => {
  return (
    <div className={`card ${noMessage ? "single-card" : ""}`} data-aos={aos}>
      <div className="icon">
        <img src={icon} alt="icon" />
      </div>
      <h4>{title}</h4>
      <p>{desc}</p>
      {/* {demo ? (
        <div className="link card-input">
          <input type="email" placeholder="Email address" />
          <Link to={linkUrl} onClick={handleClick}>
            {linkText}{" "}
            <span>
              <svg
                width="7"
                height="10"
                viewBox="0 0 7 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.43994 0.760254L5.43994 4.76025L1.43994 8.76025"
                  stroke="white"
                  strokeWidth="1.36"
                />
              </svg>
            </span>
          </Link>
        </div>
      ) : ( */}
      <div className="link">
        <Link to={linkUrl} onClick={handleClick}>
          {linkText}{" "}
          <span>
            <svg
              width="7"
              height="10"
              viewBox="0 0 7 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.43994 0.760254L5.43994 4.76025L1.43994 8.76025"
                stroke="white"
                strokeWidth="1.36"
              />
            </svg>
          </span>
        </Link>
      </div>
      {/* )} */}
    </div>
  );
};

export default ContactCard;

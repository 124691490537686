import React from "react";
import { NavLink } from "react-router-dom";
import "./DigitalAsset.scss";

const DigitalAsset = () => {
  return (
    <section className="section terms-hero">
      <div className="container">
        <div className="content">
          <h2>Digital Asset Pledge Terms</h2>
        </div>
        <div className="desc">
          <h5>INTRODUCTION</h5>
          <p>
            Welcome to Bitline. Our Digital Asset Pledge Account allows you to
            deposit digital assets with Bitline with the option for Bitline to
            use these assets to create conditional sales transactions to a
            Vendor designated by you. Bitline will provide a secure custody
            environment for digital asset safe keeping and provide a list of
            vendors for the Client to designate a beneficiary of the
            transaction.
            <br />
            <br />
            Please read and keep these Account Terms (“
            <b>Digital Asset Pledge Account Terms</b>”). If you need a physical
            copy of these Digital Asset Pledge Account Terms or if you have any
            questions, you can contact us via email at support@Bitline.io or
            visit Bitline.com. These Digital Asset Pledge Account Terms are
            incorporated by reference into the Bitline Terms of Service found
            here:{" "}
            <NavLink to={"/terms-of-use"}>
              https://bitline.io/terms-of-use/
            </NavLink>
          </p>
          <br />
          <h4>OUR RELATIONSHIP</h4>
          <p>
            In these Digital Asset Pledge Account Terms, holders of a Digital
            Asset Pledge Account, including owners, employees, agents and
            affiliates of any business, corporation, partnership or other entity
            that is, or is considering becoming, an account holder are sometimes
            referred to in the Digital Asset Pledge Account Terms as “you” or
            “your” or the “Client” or the “Customer. The provider of the Digital
            Asset Pledge Account, Ibanera LLC, is referred to as “Bitline,”
            “we,” “us” or “our” in these Digital Asset Pledge Account Terms.
            Together, you and we may be collectively referred to as “Parties.
          </p>
          <br />
          <h4>ADDITIONAL DEFINITIONS</h4>
          <p>
            <b>"Additional Balance"</b> shall mean Digital Assets or Cash.
          </p>
          <p>
            <b>"Additional Balance Threshold"</b> shall mean an amount equal to
            one hundred thirty percent (130%) of the Requested Amount.
          </p>
          <p>
            <b>"Approved Currency"</b> shall mean the applicable Fiat Currency.
          </p>
          <p>
            <b>"Business Day"</b> shall mean any day other than a Saturday,
            Sunday or a banking holiday in the United States of America.
          </p>
          <p>
            <b>"Calendar Day"</b> shall mean each and every day of the week.
          </p>
          <p>
            <b>"Balance"</b> shall mean the (i) Digital Assets deposited with
            the Custodian, including, without limitation, any Digital Assets
            deposited as Additional Balance required for a conditional sale;
            (ii) any New Tokens issued with respect to any Digital Assets under
            clause (i) following a Hard Fork; (iii) all private keys associated
            with the foregoing; (iv) all Cash Balance, including any Cash
            constituting Additional Balance; (v) any replacements thereof; (v)
            all books and records relating thereto; and (vi) all proceeds
            thereof.
          </p>
          <p>
            <b>"Actual Value"</b> shall mean, at any time, the aggregate value,
            measured in Fiat Currency, of all Digital Assets comprising the
            Conditional Sale at the then-applicable Conversion Rate, as
            determined by Bitline in its sole discretion.
          </p>
          <p>
            <b>"Conversion Rate"</b> shall mean any conversion between a Digital
            Asset and Fiat Currency made by Bitline in accordance with the rates
            and prices applicable at the actual time of conversion. Applicable
            rates are indexed to those used by industry-leading platforms, as
            Bitline may choose to use from time to time, in its sole discretion.
            Parties may currently use rates provided and determined by our
            liquidity providers. Bitline may change these rate sources at any
            time and without giving prior notice or updating these Terms, and
            Customer shall not have any claims regarding its choice of rate
            sources or rates made available by any third party.
          </p>
          <p>
            <b>"Custodian"</b> shall mean Ibanera Pte Ltd.
          </p>
          <p>
            <b>"Custodial Agreement"</b> shall mean an agreement for custodial
            services, executed by Bitline, Customer and Custodian, in form and
            substance satisfactory to Bitline.
          </p>
          <p>
            <b>"Digital Asset"</b> shall mean Bitcoin (BTC), Ethereum (ETH) or
            any other cryptocurrency or other digital assets stored in the
            blockchain as may be agreed in writing between Bitline and Customer.
          </p>
          <p>
            <b>"Early Settlement Threshold"</b> shall mean an amount equal to
            one hundred twenty percent (120%) of the Requested Amount.
          </p>
          <p>
            <b>"Effective Date"</b> shall mean the date upon which all
            conditions in Section 5 have been satisfied.
          </p>
          <p>
            <b>"Fiat Currency"</b> shall mean a government-issued currency that
            is not backed by a physical commodity, such as gold or silver, for
            example the U.S. dollar, and which is agreed upon by the parties as
            the applicable fiat currency in the Addendum to this Agreement.
          </p>
          <p>
            <b>"Hard Fork"</b> means a permanent divergence in the blockchain,
            which commonly occurs when non-upgraded nodes cannot validate blocks
            created by upgraded nodes that follow newer consensus rules, or an
            airdrop or any other event which results in the creation of a new
            token.
          </p>
          <p>
            <b>"Maturity Date"</b> shall mean the date that is thirty (30) days
            following the Effective Date.
          </p>
          <p>
            <b>"Merchant"</b> shall mean Vendors supporting the Product.
          </p>
          <p>
            <b>"Minimum Balance Amount"</b> means an amount equal to two (2)
            times the Requested Amount.
          </p>
          <p>
            <b>"New Tokens"</b> shall mean any tokens representing any balance
            amount that is created as a result of a Hard Fork.
          </p>
          <p>
            <b>"Product"</b> Product
          </p>
          <p>
            <b>"Return of Balance"</b> shall mean the delivery by Custodian to
            Customer of all Digital Assets constituting Collateral, together
            with all private keys associated therewith [in accordance with the
            Custodial Agreement].
          </p>
          <p>
            <b>"Sanctions"</b> shall mean sanctions administered or enforced by
            the U.S. Department of the Treasury’s Office of Foreign Assets
            Control (commonly known as “OFAC”), the U.S. Department of State,
            the United Nations Security Council, or other relevant sanctions
            authority.
          </p>
          <p>
            <b>"Settlement Date"</b> shall mean the date upon which any
            Settlement Event occurs.
          </p>
          <p>
            <b>"Solvent"</b> shall mean with respect to any person on any date
            of determination, that on such date (1) the fair value of the
            property of such person is greater than the total amount of
            liabilities, including contingent liabilities, of such person; (2)
            the present fair salable value of the assets of such person is not
            less than the amount that will be required to pay the probable
            liability of such person on its debts as they become absolute and
            matured; (3) such person does not intend to, and does not believe
            that it will, incur debts or liabilities beyond such Person’s
            ability to pay such debts and liabilities as they mature; (4) such
            person is not engaged in business or a transaction, and is not about
            to engage in business or a transaction, for which such person’s
            property would constitute an unreasonably small capital; and (5)
            such person is able to pay its debts and liabilities, contingent
            obligations and other commitments as they mature in the ordinary
            course of business.
          </p>
          <br />
          <h4>ACCEPTANCE OF TERMS</h4>
          <p>
            By opening a Digital Asset Pledge Account, you agree that the terms
            and conditions contained herein will govern your Digital Asset
            Pledge Account. Any Digital Asset Pledge Account will be governed by
            these Digital Asset Pledge Account Terms, as modified from time to
            time. We reserve the right to update these Digital Asset Pledge
            Account Terms at any time, and may notify you of such changes via
            the Website or by email at the address specified on your account
            application, but we have no obligation to notify you of every
            update.
          </p>
          <br />
          <br />
          <p>A. Opening a Digital Asset Pledge Account</p>
          <br />
          <ul className="dot-list">
            <li>
              Anyone residing in a jurisdiction where we offer a Digital Asset
              Pledge Account and who is at least twenty-one (21) years old can
              apply to open a Digital Asset Pledge Account. Digital Asset Pledge
              Accounts can be opened at Bitline.io, the Bitline app or by
              contacting the Customer Care Center. All Digital Assets received
              will initially be deposited into an account with the Custodian.
            </li>
            <li>
              In order to open your account, we will first verify your identity
              and require you to provide acceptable forms of identification. To
              help the government fight the funding of terrorism and money
              laundering activities, United States federal law requires us to
              obtain, verify, and record information that identifies each person
              or entity that opens an account. We may also ask for your driver’s
              license or other identifying documents. For business accounts, we
              may require identification information related to a controlling
              manager and/or equity owners of your business. You represent and
              warrant that any and all information provided to us pursuant to
              these Digital Asset Pledge Account Terms or otherwise is true,
              accurate and not misleading in any respect. If any such
              information changes, it is your obligation to update such
              information as soon as possible. Note that we may use credit
              reports or other information from third parties to help us
              determine if we should open or maintain your account.
            </li>
            <li>
              Digital Asset Pledge Accounts are not available in Cuba, Iran,
              North Korea, Sudan, Syria, or any other country to which the
              United States embargoes goods or imposes similar sanctions.
              Digital Asset Pledge Accounts are not available in the State of
              New York, or any other jurisdiction which we determine we would
              not be able to or chose not to offer Digital Asset Pledge
              Accounts. Due to the dynamic nature of regulatory requirements and
              interpretations in the cryptocurrency market, we reserve the right
              to update the list of prohibited jurisdictions and reject
              applications to open accounts that we determine we would be unable
              to accept for regulatory or policy reasons in our sole and
              absolute discretion.
            </li>
            <li>
              Your account is not deemed to be opened, and we have no
              responsibility to you unless and until you have received written
              (which may be electronic) confirmation from us that your account
              has been opened. We are not obligated to accept an application
              from any applicant, and we reserve the right not to open an
              account for any applicant in our sole and absolute discretion.
            </li>
          </ul>
          <br />
          <p>B. Funding your Digital Asset Pledge Account</p>
          <br />
          <ul className="dot-list">
            <li>
              You can open your account by transferring eligible Digital Assets
              to the wallet address provided in your Bitline account. Your
              Digital Assets will be accepted in Custody by Ibanera Pte Ltd LLC.
            </li>
            <li>
              The transfer of such eligible Digital Asset to your Digital Asset
              Pledge Account may not be deemed settled and completed until the
              transaction has met the specific protocol for a relevant
              cryptocurrency that Bitline has defined.
            </li>
          </ul>
          <br />
          <h4>Issuance of the Product.</h4>
          <p>
            Once a Customer has funded a Digital Asset Account, he or she may
            use the Digital Asset as further defined and request a standby
            Conditional Sale in a certain amount of (the “ Requested Amount”) in
            favor of Merchant to secure certain obligations of the Customer.
            Subject to satisfaction of the conditions set forth herein,
            including delivery of the Balance, Bitline shall deliver the Product
            for the Requested Amount.
          </p>
          <br />
          <h4>Fees</h4>
          <p>
            As consideration for Bitline’s services hereunder, Customer shall
            pay a fee equal to ten percent (10%) of the Requested Amount (the
            “Service Fee”). The Service Fee shall be immediately and fully
            earned on the Effective Date, and shall be payable on the
            Termination Date. The Service Fee shall be nonrefundable. All
            payments of the Service fee shall be made without deduction or
            withholding for any taxes.
          </p>
          <br />
          <p>
            In the event that the Customer terminates this agreement prior to
            the Maturity Date (and so long as no other Termination Event has
            then occurred), the Customer shall be entitled to a credit as
            follows: (i) if termination occurs on or before the seventh (7th)
            day following the Effective Date, fifty percent (50%) of the amount
            of the Service Fee and (ii) if termination occurs after the seventh
            (7th) day and on or before the fourteenth (14th) day following the
            Effective Date, twenty-five percent (25%) of the amount of the
            Service Fee. There shall be no credit available against the Service
            Fee after the fourteenth (14th) day following the Effective Date.
          </p>
          <br />

          <h4>Deposit of Balance; Grant of Security Interest .</h4>
          <p>
            Once a customer Account is funded, the Customer shall deliver via
            the Online Platform to the Custodian Digital Assets with a Balance
            Value as of the time of transfer to the Custodian of not less than
            the Minimum Balance Amount.
          </p>
          <br />

          <p>
            By its execution of this Agreement, the Customer hereby pledges,
            assigns and transfers to Bitline, and here by grants to Bitline, a
            lien on and security interest in all of the Digital Assets, whether
            now owned or at any time hereafter acquired as a Balance for the
            prompt and complete payment and performance when due (whether at the
            stated maturity, by acceleration or otherwise) of its obligations
            hereunder and in connection with the Product.
          </p>
          <br />
          <p>
            The Custodian shall hold the Balance in a non-interest bearing
            account, for the benefit of Bitline. Following deposit of the
            Digital Assets with the Custodian, the Customer shall have no rights
            or ability to direct the use or disposition of the Digital Assets,
            or to otherwise direct the Custodian with respect to the Digital
            Assets prior to a Return of the Digital Assets.
          </p>
          <br />

          <p>
            The Customer authorizes Bitline to file one or more financing or
            continuation statements, and amendments thereto (or similar
            documents required by any laws of any applicable juris
          </p>
        </div>
      </div>
    </section>
  );
};

export default DigitalAsset;

export const jobDetail = [
  {
    jobCategory: "Design",
    shortCat: "Design",
    jobColor: "",
    jobTitle: "Product Designer",
    jobDesc: "We're looking for a mid-level product designer to join our team.",
    jobUrl: "",
    jobType: "Remote",
    jobTime: "Full-time",
  },
  {
    jobCategory: "Software Development",
    shortCat: "Software",
    jobColor: "",
    jobTitle: "Engineering Manager",
    jobDesc: "We're looking for a mid-level product designer to join our team.",
    jobUrl: "",
    jobType: "Remote",
    jobTime: "Full-time",
  },
  {
    jobCategory: "Customer Success",
    shortCat: "CX",
    jobColor: "",
    jobTitle: "Customer Success Manager",
    jobDesc: "We're looking for a mid-level product designer to join our team.",
    jobUrl: "",
    jobType: "Remote",
    jobTime: "Full-time",
  },
  {
    jobCategory: "Sales",
    shortCat: "Sales",
    jobColor: "",
    jobTitle: "Account Executive",
    jobDesc: "We're looking for a mid-level product designer to join our team.",
    jobUrl: "",
    jobType: "Remote",
    jobTime: "Full-time",
  },
  {
    jobCategory: "Marketing",
    shortCat: "Marketing",
    jobColor: "",
    jobTitle: "SEO Marketing Manager",
    jobDesc: "We're looking for a mid-level product designer to join our team.",
    jobUrl: "",
    jobType: "Remote",
    jobTime: "Full-time",
  },
];

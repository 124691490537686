import React from "react";
import { NavLink } from "react-router-dom";
import "./CustodialTerms.scss";

const CustodialTerms = () => {
  return (
    <section className="section terms-hero">
      <div className="container">
        <div className="content">
          <h2>Custodial Services Terms</h2>
        </div>
        <div className="desc">
          <h5>INTRODUCTION</h5>
          <p>
            Welcome to Bitline. Our Bitline Wallet is a non-interest-bearing
            crypto custody account that allows you to hold, transfer and manage
            your cryptocurrency held in your account. Please read and keep these
            Bitline Wallet Terms (“Wallet Terms”). If you need a physical copy
            of these Wallet Terms or if you have any questions, you can contact
            us via email at support@Bitline.io or visit Bitline.io. These Wallet
            Terms are incorporated by reference into the Bitline Terms of
            Service found here:
            <NavLink to={"/terms-of-use"}>
              https://bitline.io/terms-of-use/
            </NavLink>
          </p>
          <br />
          <h4>OUR RELATIONSHIP</h4>
          <p>
            In these Wallet Terms, holders of a Bitline Wallet, including
            owners, employees, agents and affiliates of any business,
            corporation, partnership or other entity that is, or is considering
            becoming, an account holder are sometimes referred to in the Wallet
            Terms as “you” or “your” or the “Client” or the “Customer”. The
            provider of the Bitline Wallet, Ibanera Pte Ltd, is referred to as
            “Bitline,” “we,” “us” or “our” in these Wallet Terms. Together, you
            and we may be collectively referred to as “Parties.”
          </p>
          <br />
          <h4>ACCEPTANCE OF TERMS</h4>
          <p>
            By opening a Bitline Wallet, you agree that the terms and conditions
            contained in these Wallet Terms, as modified from time to time, will
            govern your Bitline Wallet. We reserve the right to update these
            Wallet Terms at any time and may notify you of such changes via the
            Website or by email at the address specified on your account
            application, but we have no obligation to notify you of every
            update.
          </p>
          <br />
          <h4>Opening a Bitline Wallet</h4>

          <br />
          <ul className="dot-list">
            <li>
              Anyone residing in a jurisdiction where we offer a Bitline Wallet
              and who is at least twenty-one (21) years old can apply to open a
              Bitline Wallet. Bitline Wallet can be opened at Bitline.com, the
              Bitline app or by contacting us here. All crypto received will
              initially be transferred to an account at one of Bitline’s or its
              affiliates’ institutional custodians.
            </li>
            <li>
              In order to open your account, we will first verify your identity
              and require you to provide acceptable forms of identification. To
              help the governments fight the funding of terrorism and money
              laundering activities, laws and regulations requires us to obtain,
              verify, and record information that identifies each person or
              entity that opens an account. We may also ask for your driver’s
              license or other identifying documents. For business accounts, we
              may require identification information related to a controlling
              manager and/or equity owners of your business. You represent and
              warrant that all information provided to us pursuant to these
              Wallet Terms or otherwise is true, accurate and not misleading in
              any respect. If any such information changes, it is your
              obligation to update such information as soon as possible. Note
              that we may use credit reports or other information from third
              parties to help us determine if we should open or maintain your
              account.
            </li>
            <li>
              Bitline Wallet is not available in Cuba, Iran, North Korea, Sudan,
              Syria, or any other country to which the United States embargoes
              goods or imposes similar sanctions. Bitline Wallet is not
              available in the State of New York, or any other jurisdiction
              which we determine we would not be able to offer or chose not to
              offer a Bitline Wallet. Due to the dynamic nature of regulatory
              requirements and interpretations in the cryptocurrency market, we
              reserve the right to update the list of prohibited jurisdictions
              and reject applications to open accounts that we determine we
              would be unable to accept for regulatory or policy reasons in our
              sole and absolute discretion.
            </li>
            <li>
              Your account is not deemed to be opened, and we have no
              responsibility to you unless and until you have received written
              (which may be electronic) confirmation from us that your account
              has been opened. We are not obligated to accept an application
              from any applicant, and we reserve the right not to open an
              account for any applicant in our sole and absolute discretion.
            </li>
          </ul>
          <br />
          <h4>Funding your Bitline Wallet</h4>

          <br />
          <ul className="dot-list">
            <li>
              You can open your account by transferring eligible cryptocurrency
              to the wallet address provided in your Bitline account.
            </li>
            <li>
              The transfer of such eligible cryptocurrency to your Bitline
              Wallet may not be deemed settled and completed until the
              transaction has met the specific protocol for a relevant
              cryptocurrency that Bitline has defined.
            </li>
            <li>
              In addition, you can open your account or add additional assets to
              your Bitline Wallet by sending a wire transfer or ACH from a bank
              account to Ibanera LLC (Wyoming) to purchase certain
              cryptocurrencies as published on our website from time to time.
              Trading activity is governed by Ibanera LLC's Trading Terms. If
              you have a Bitline Wallet, all assets purchased through Ibanera
              LLC, shall be immediately debited and credited to your Bitline
              Wallet held by Bitline, and Ibanera LLC will have no further
              liability or obligation to you in connection with such assets.
            </li>
            <li>
              As of the date of these Wallet Terms, there is no minimum amount
              required to open a Bitline Wallet. We may, in our sole discretion,
              institute a minimum balance in the future, and such minimum
              balance will apply to your Bitline Wallet. Once your account has
              been opened and funded, Bitline requires seven calendar days’
              notice prior to your complete or partial withdrawal of the
              eligible crypto in your Bitline Wallet.
            </li>
          </ul>
          <br />
          <h4>How Accounts Can Be Owned</h4>

          <br />
          <p>
            Bitline Wallet can be held in the types of ownership described
            below. Each individual or business entity shall only be permitted to
            maintain a single Bitline Wallet at any given time.
          </p>
          <br />
          <ul className="dot-list">
            <li>
              Individual Account. This account is owned by only one person or
              entity who can transfer or withdraw cryptocurrency held in the
              account. There is a 1 (one) individual Bitline Wallet maximum per
              individual.
            </li>
            <li>
              Business Account. This account is owned by a corporation,
              unincorporated association, limited liability company, limited
              liability partnership, fiduciary, partnership, sole proprietorship
              or other entity holding an account in any capacity other than an
              individual capacity. Each person or entity completing any account
              opening requirements represents and agrees that he or she (i) is
              fully authorized to execute all documents or otherwise complete
              our requirements in his or her stated capacity, (ii) has furnished
              all documents or other information necessary to demonstrate that
              authority, and (iii) will furnish other documents and complete
              other requirements as we may request from time to time. We may
              refuse to recognize any resolution affecting your business account
              that appears to us to be incomplete or improperly executed.
            </li>
          </ul>
          <br />
          <h4>Withdrawals</h4>

          <br />
          <ul className="dot-list">
            <li>
              You may make a complete or partial withdrawal of cryptocurrency
              from your Bitline Wallet at any time with appropriate time notice
              as stated above. Additionally, before a withdrawal is processed,
              you may be required to provide certain personally identifiable
              information so Bitline can verify your identity. Bitline initiates
              the withdrawal process instantly when possible, and we may require
              up to seven (7) days after you submit your withdrawal request to
              process the withdrawal.
            </li>
            <li>
              Withdrawal limits based on frequency and amount may apply from
              time-to-time and will be described in your Bitline Wallet
              interface and on our Website.
            </li>
            <li>
              In the case of any withdrawal, you will be required to provide the
              details for the account to which you wish to transfer your
              cryptocurrency. Assets subject to a withdrawal request cannot be
              traded.
            </li>
            <li>
              If the details you provide are inaccurate or incomplete, your
              cryptocurrency may be permanently lost. When you withdraw all or
              any of your cryptocurrency, we will not be liable for any loss
              that results from inaccurate, incomplete or misleading details
              that you may provide for such transfer. If the account you specify
              is one to which we are unable to process transfers, we will have
              no liability for any failure or delay in processing your requested
              withdrawal.
            </li>
            <li>
              Bitline and our third-party partners may experience cyber-attacks,
              extreme market conditions, or other operational or technical
              difficulties which could result in the immediate halt of transfers
              and withdrawals of cryptocurrency either temporarily or
              permanently. Bitline is not and will not be responsible or liable
              for any loss or damage of any sort incurred by you as a result of
              such cyber-attacks, operational or technical difficulties or
              suspensions of transfers or withdrawals.
            </li>
          </ul>
          <br />

          <h4>Taxes</h4>

          <br />
          <ul className="dot-list">
            <li>
              You will be able to see a record of the transactions related to
              your Bitline Wallet which you may wish to use for the purposes of
              making any required tax filings or payments. It is your
              responsibility to determine what, if any, taxes apply to the
              payments you make or receive, and to collect, report, and remit
              the correct tax to the appropriate tax authority.
            </li>
            <li>
              We will make any tax withholdings or filings that we are required
              by law to make, but we are not responsible for determining whether
              taxes apply to your transaction, or for collecting, reporting, or
              remitting any taxes arising from any transaction. You are
              responsible for complying with all applicable law, whether
              federal, state, local, or non-U.S. You agree that Bitline is not
              responsible for determining whether or which laws may apply to
              your transactions, including tax law. You are solely responsible
              for reporting and paying any taxes arising from your Bitline
              Wallet.
            </li>
          </ul>
          <br />
          <h4>Consent to Utilize Assets</h4>

          <br />
          <ul className="dot-list">
            <li>
              Except where prohibited or limited by applicable law, in
              consideration for Digital Pledge Guarantee Product, you grant
              Bitline the right, without further notice to you, to hold the
              cryptocurrency held in your account in Bitline’s name or in
              another name, and to pledge, repledge, hypothecate, rehypothecate,
              sell, lend, or otherwise transfer, invest or use any amount of
              such cryptocurrency, separately or together with other property,
              with all attendant rights of ownership, and for any period of time
              and without retaining in Bitline’s possession and/or control a
              like amount of cryptocurrency, and to use or invest such
              cryptocurrency at its own risk.
            </li>
            <li>
              You acknowledge that, with respect to assets used by Bitline
              pursuant to this paragraph: (i) you may not be able to exercise
              certain rights of ownership, (ii) Bitline may receive compensation
              in connection with lending or otherwise using or investing
              cryptocurrency in its business to which you will have no
              entitlement, and (iii) cryptocurrency that is subject to such
              lending transactions, investment or otherwise being used in these
              transactions will not be held by Bitline’s third party custodians.
            </li>
          </ul>
          <br />
          <h4>Ownership of Cryptocurrency</h4>

          <br />
          <ul className="dot-list">
            <li>
              You hereby represent and warrant to us at all times during which
              you hold cryptocurrency in your Bitline Wallet that: (i) any
              cryptocurrency controlled by you in connection with your Bitline
              Wallet is owned by you except as set forth in these - Wallet
              Terms; and (ii) you are validly authorized to carry out
              transactions using such cryptocurrency and that all transactions
              initiated with your Bitline Wallet are for your own account (or,
              in the case of business accounts, for your business’s account) and
              not on behalf of any other person or entity.
            </li>
          </ul>
          <br />
          <h4>General Rules Governing Bitline Wallet</h4>

          <br />
          <ul className="dot-list">
            <li>
              All our actions relating to your account, including these Wallet
              Terms, will be governed by the laws and regulations of the United
              States and, to the extent not preempted, the laws and regulations
              of the State of Delaware and Singapore. Any lawsuit regarding your
              account must be brought in a proper court in the State of
              Delaware. If any part of these Wallet Terms is determined to be
              invalid or unenforceable, such determination will not affect the
              remainder of these Wallet Terms.
            </li>
            <li>
              We reserve the right at all times to monitor, review, retain
              and/or disclose any information as necessary to satisfy any
              applicable law, regulation, legal process or governmental request.
            </li>
            <li>
              All transfers to your Bitline Wallet must consist of eligible
              cryptocurrency and must be transferred to the wallet address
              provided in your Bitline account application, unless we notify you
              otherwise. We have the right to reject any transfer and the right
              to return cryptocurrency already transferred into your Bitline
              Wallet. Any contribution meeting the confirmation guidelines in
              Section B.2 on any day at or before 11:59 p.m. UTC-4 will be
              treated by us as being received on such day. If you attempt to
              transfer assets to an unsupported wallet or using an unsupported
              network, or if you attempt to transfer assets other than eligible
              cryptocurrency (including any unsupported digital assets) to your
              Bitline Wallet, such assets may be permanently lost, and we will
              have no liability for any such loss.
            </li>
            <li>
              These Wallet Terms and the relationship created hereby do not
              create a fiduciary relationship between us.
            </li>
            <li>
              We may follow instructions regarding your Bitline Wallet if we
              reasonably believe that you have authorized the instructions.
            </li>
            <li>
              We will make statements for your Bitline Wallet available to you
              by email and/or online at Bitline.Io. All your accounts and (in
              our discretion) those of any of your affiliates may be combined
              into one statement.
            </li>
            <li>
              We will make available to you a periodic statement showing the
              activity on your account and containing information sufficient to
              allow you to reasonably identify transactions. You must examine
              the statement and notify us of any unauthorized use or any error
              or irregularity on the statement within 30 calendar days after the
              statement is sent or made available to you. If notice is not
              received within the 30-calendar day period, then we will have no
              liability to you and any losses will be entirely yours.
            </li>
            <li>
              Bitline Wallet are not transferable or assignable to another
              person in whole or in part.
            </li>
            <li>
              Bitline is required by law to turn over the assets in abandoned or
              unclaimed customer accounts to the state of your last known
              residence. You must promptly notify us of any change in your
              residential mailing or email address. Failure to notify us may
              result in delay or non-receipt of Bitline correspondence.
            </li>
            <li>
              We may record and monitor our telephone conversations with you and
              your electronic communications with us (chat, e-mail and other
              forms of electronic exchange). Unless the law requires otherwise,
              you consent in advance to such recording and monitoring, and we do
              not need to remind you of these activities
            </li>
            <li>
              For purposes of these Wallet Terms, our business days are Monday
              through Friday. Saturdays, Sundays and all federal and Delaware
              State holidays are not included.
            </li>
            <li>
              We reserve the right to limit access to your accounts, which can
              include temporarily or permanently removing your online access,
              restricting your account and/or closing your accounts without
              prior notice to you unless prior notice is required by law. We do
              not bear liability for such actions. In addition, Bitline reserves
              the right to withhold or delay the withdrawal of funds or assets
              belonging to you if you fail to comply with these Wallet Terms.
            </li>
          </ul>
          <br />
          <h4>
            {" "}
            Indemnification and Limitation of Liability; Attorney’s Fees and
            Costs for Lawsuits
          </h4>
          <ul className="dot-list">
            <li>
              You will indemnify and hold us and our affiliates harmless from
              any losses, damages, suits and expenses, of whatever kind,
              including reasonable attorneys’ fees, which we may incur in
              connection with or arising out of your use of your Bitline Wallet
              or our activities in connection with such account, your violation
              of any law, regulation, order or other legal mandate, or the
              rights of a third party, or any act or omission by your agent,
              representative or third-party service provider while using your
              Bitline Wallet, regardless of whether the specific use was
              expressly authorized by you.
            </li>
            <li>
              We are not liable to you for claims, costs, losses or damages
              caused by an event that is beyond our reasonable control (e.g.,
              the acts or omissions of third parties, natural disaster,
              emergency conditions, disease epidemics/pandemics such as
              Covid-19, government action, equipment or communications
              malfunction).
            </li>
            <li>
              We are not liable for special, incidental, exemplary, punitive or
              consequential losses or damages of any kind.
            </li>
            <li>
              Our liability to you for a claim is limited to the face value of
              the item or transaction, or the actual value of any assets not
              properly credited or debited.
            </li>
          </ul>
          <br />
          <h4>Risk Disclosure</h4>

          <br />
          <ul className="dot-list">
            <li>
              Your Bitline Wallet is not a checking or savings account, and it
              is not covered by insurance against losses. We will pledge,
              repledge, hypothecate, rehypothecate, sell, lend, or otherwise
              transfer or use funds and cryptocurrency assets to counterparties,
              and such cryptocurrency assets will be exposed to various risks as
              a result of such transactions in certain jurisdictions,
              cryptocurrency is not legal tender, and is not backed by the
              government. Accounts and value balances are not subject to Federal
              Deposit Insurance Corporation or Securities Investor Protection
              Corporation protections. Legislative and regulatory changes or
              actions at the state, federal, or international level may
              adversely affect the use, transfer, exchange, and value of
              cryptocurrency.
            </li>
            <li>
              Transactions in cryptocurrency may be irreversible, and,
              accordingly, losses due to fraudulent or accidental transactions
              may not be recoverable. If you attempt to transfer assets to an
              unsupported wallet or using an unsupported network, or if you
              attempt to transfer assets other than eligible cryptocurrency
              (including any unsupported digital assets) to your Bitline Wallet,
              such assets may be permanently lost. We assume no liability,
              obligation or responsibility whatsoever with respect to any
              unsupported digital asset or any asset that is transferred using
              an unsupported network or to an unsupported wallet. Some
              cryptocurrency transactions shall be deemed to be made when
              recorded on a public ledger, which is not necessarily the date or
              time that the customer initiates the transaction. The value of
              cryptocurrency may be derived from the continued willingness of
              market participants to exchange government-issued currency for
              cryptocurrency, which may result in the potential for permanent
              and total loss of value of a particular cryptocurrency should the
              market for that cryptocurrency disappear.
            </li>
            <li>
              There is no assurance that a person who accepts a cryptocurrency
              as payment today will continue to do so in the future. The
              volatility and unpredictability of the price of cryptocurrency
              relative to government-issued currency may result in significant
              loss over a short period of time. The nature of cryptocurrency may
              lead to an increased risk of fraud or cyber-attack, including
              rollback attacks or blockchain reorganizations. The nature of
              cryptocurrency means that any technological difficulties
              experienced by Bitline may prevent the access or use of a
              customer’s cryptocurrency. Any bond or trust account maintained by
              Bitline for the benefit of its customers may not be sufficient to
              cover all losses incurred by customers. Considering these risks,
              you should carefully consider whether holding cryptocurrency in a
              Bitline account is suitable.
            </li>
          </ul>
          <br />
          <h4>Conflict/Disputes Involving Your Account</h4>

          <br />
          <ul className="dot-list">
            <li>
              We are not liable to you for errors that do not result in
              financial loss to you. We may take any action authorized or
              permitted by these Wallet Terms without being liable to you, even
              if such action causes you to incur fees, expenses or damages.
            </li>
            <li>
              If third parties make claims on your account, or if we receive
              conflicting instructions from authorized signers, or if we become
              involved in or concerned about a dispute between you and an
              authorized signer, we have discretion to act in ways we believe to
              be appropriate, including by closing your Bitline Wallet and
              returning the cryptocurrency in such account. You are liable for
              all expenses and fees we incur for such conflicts or disputes,
              including internal costs and attorneys’ fees, and we may charge
              them to your Bitline Wallet.
            </li>
            <li>
              Fraudulent activity, including any attempt to withdraw assets that
              you do not own, did not transfer into your Bitline Wallet, or did
              not purchase, is strictly prohibited and may result in the closure
              of your Bitline Wallet. Fraudulent activity includes any attempt
              to take advantage of errors on the Bitline Website, systems,
              applications, or technology platforms. Any errors identified
              should be flagged to Support@Bitline.io for appropriate
              compensation, where applicable.
            </li>
          </ul>
          <br />
          <h4>Legal Process Affecting Accounts</h4>

          <br />
          <ul className="dot-list">
            <li>
              If legal action such as an attachment, garnishment, seizure,
              liquidation, levy or other state or federal legal process (“legal
              process”) is brought against your Bitline Wallet, we may refuse to
              permit (or may limit) withdrawals or transfers from your account
              until the legal process is satisfied or dismissed. We may also be
              required to transfer the assets in your Bitline Wallet at the
              behest of governmental authorities pursuant to legal and
              regulatory actions. Regardless of the terms of such attachment,
              garnishment, levy or other state or federal process, we have first
              claim to all assets in your account.
            </li>
            <li>
              We will not contest on your behalf any such legal process and may
              take action to comply with such legal process as we determine to
              be appropriate in the circumstances without liability to you. If
              we incur any expenses, including without limitation, reasonable
              attorney fees, in connection with any such legal process, we may
              charge any expenses and fees to your account or any other account
              you may have with us without prior notice to you, or we may bill
              you directly for such expenses and fees. Any garnishment, seizure,
              liquidation, or other levy against your account is subject to our
              right of setoff.
            </li>
          </ul>
          <br />
          <h4>Setoff and Security Interest Rights</h4>

          <br />
          <ul className="dot-list">
            <li>
              You grant us a security interest in any and all of your Bitline
              account(s) with us for obligations owing to us or any of our
              affiliates by any owner of any of your accounts. These obligations
              include both secured and unsecured debts and debts you owe
              individually or together with someone else, including debts and
              obligations under other transactions or agreements between you and
              us or any of our affiliates.
            </li>
            <li>
              We may take or set off assets in your Bitline Wallet, or transfer
              assets between any or all of your Bitline Wallet, with us or any
              of our affiliates for direct, indirect and acquired obligations
              that you owe us or our affiliates, including any balances as a
              result of not having sufficient assets available or as a result of
              an erroneous transfer of assets to an address under your control,
              or a return or other negative balance, regardless of the source of
              assets in an account.
            </li>
            <li>
              These rights are in addition to other rights we have to take,
              transfer or charge assets in your account for obligations you owe
              us or our affiliates. We may consider these Wallet Terms as your
              consent to Bitline’s asserting its security interest or exercising
              its right of setoff should any laws governing your account require
              your consent.
            </li>
            <li>
              If the law restricts our ability to take, transfer or setoff
              against assets in your account, or if some contributions are
              protected from attachment, levy or legal process to the extent
              that you may do so by contract, you waive those conditions and
              limits and authorize us to apply assets in any or all of your
              accounts with us to obligations you owe us. Exercising these
              rights may result in early withdrawal penalties.
            </li>
            <li>
              We hereby agree that, to the extent permitted by applicable law,
              you may take or set off assets in your account, or any amounts we
              owe you with respect thereto, against the Obligations. If the law
              restricts your ability to take, transfer or setoff assets in your
              account, or if some contributions are protected from attachment,
              levy or legal process to the extent that we may do so by contract,
              we waive those conditions and limits and authorize you to apply
              assets in any or all of your accounts with us to the Obligations.
            </li>
          </ul>
          <br />
          <h4>Waiver</h4>

          <br />
          <ul className="dot-list">
            <li>
              We may delay or waive any rights we have under these Wallet Terms.
              If we delay or waive our rights, you are still obligated to pay us
              fees and other amounts you may owe us under these Wallet Terms.
              Any delay or waiver of our rights applies only to the specific
              instance in which we decide to delay or waive the provision and
              does not affect our future rights in any way.
            </li>
          </ul>
          <br />
          <h4>Digital Images and e-Signatures</h4>

          <br />
          <ul className="dot-list">
            <li>
              We accept digital images and e-signatures for documents that need
              to be signed. However, we will not be liable if if the size, color
              or quality of the signature is different from that of any
              signature previously presented to us.
            </li>
          </ul>
          <br />
          <h4>Closing an Account</h4>

          <br />
          <ul className="dot-list">
            <li>
              We have the right to close your Bitline Wallet at any time for any
              reason without advance notice. In addition, your account will be
              closed automatically if we do not receive your initial transfer of
              assets to your Bitline Wallet within sixty (60) days of the date
              on which your account is opened. If your account has a balance
              when we close it, and you provide a valid wallet address to us, we
              will return the remaining crypto assets to that address, unless
              prohibited by applicable law. We will cooperate with any
              regulatory or governmental entity’s instructions or requests
              regarding your Bitline Wallet, including freezing it and seizing
              its assets if mandated.
            </li>
          </ul>
          <br />
          <h4>Liability for Failure to Make Transfers</h4>

          <br />
          <ul className="dot-list">
            <li>
              If we do not complete a transfer to or from your Bitline Wallet or
              in the correct amount as clearly instructed to us according to
              these Wallet Terms, we will be liable for your actual losses or
              damages, subject to certain exceptions. We will not be liable, for
              instance: (i) if, through no fault of ours, you do not have enough
              assets in your account to make the transfer; (ii) if circumstances
              beyond our control (such as fire or flood) prevent the transfer,
              despite reasonable precautions that we have taken; (iii) in the
              case of preauthorized credits, if the data from the third party is
              not received, is incomplete or erroneous; (iv) if your Bitline
              Wallet is not in an active status; (v) due to legal requirements
              of regulatory restrictions; (vi) other exceptions stated in these
              Wallet Terms or in another agreement with you.
            </li>
          </ul>
          <br />
          <h4>Disclosure of Account Information</h4>

          <br />
          <ul className="dot-list">
            <li>
              We will disclose information to third parties about your account
              or the transfers you make: (i) where it is necessary for
              completing transfers; (ii) in order to verify the existence and
              condition of your Bitline Wallet for a third party, such as a
              credit bureau or merchant; (iii) in accordance with the Bitline
              Terms of Service; (iv) if you give us your written permission; (v)
              if we close your Bitline Wallet due to a deficient balance,
              excessive instances when you do not have sufficient assets in your
              account or to protect or enforce our legal rights; and (vi) in
              order to comply with government agency or court orders.
            </li>
          </ul>
          <br />
          <h4>Account Errors or Questions</h4>

          <br />
          <ul className="dot-list">
            <li>
              Our platform allows you to review your Bitline Wallet and conduct
              certain other transactions online. You must maintain adequate
              security and control of all IDs, passwords, hints, or any other
              codes that you use to access your Bitline Wallet through our
              online platform. Any loss or compromise of the foregoing
              information or your personal information may result in
              unauthorized access to your Bitline Wallet. We assume no
              responsibility for any loss that you may sustain due to compromise
              of your sensitive information. You accept all risks of
              unauthorized access and use of your Bitline Wallet.
            </li>
            <li>
              Tell us promptly if you believe that an electronic transfer has
              been made without your permission. Telephoning is the best way of
              keeping your possible losses down. You could lose all the assets
              in your Bitline Wallet (plus any other Bitline account you own).
              If your statement shows transfers that you did not make, tell us
              immediately by contacting us here.
            </li>
            <li>
              Please contact our support, as soon as you can, if you think your
              statement is wrong or if you need more information about an item
              listed on the statement. We must hear from you no later than
              thirty (30) days after we send you or make available to you the
              first statement on which the problem or error appeared.
            </li>
            <li>
              If you tell us orally or via electronic means, we may require that
              you send us your complaint or question in writing, and we may
              request additional information within ten (10) business days. We
              will endeavor to determine whether an error occurred within ten
              (10) business days after we hear from you and will correct any
              error promptly. If we need more time, however, we may take up to
              forty-five (45) days to investigate your complaint or question.
              For errors involving new accounts, we may take up to ninety (90)
              days to investigate your complaint or question. If we decide to do
              this, we will credit your account within ten (10) business days
              for the amount of the error, so that you will have the use of the
              assets during the time it takes us to complete our investigation.
              For new accounts, we may take up to twenty (20) business days to
              credit your account for the amount you think is in error.
            </li>
            <li>
              If we ask you to put your complaint or question in writing, or
              request additional information, and do not receive it within ten
              (10) business days, we may not credit your account. If we decide
              that there was no error, we will send you a written explanation.
              You may ask for copies of the documents that we used in our
              investigation.
            </li>
          </ul>

          {/* <br />
          <br />
          <br />
          <p>
            <b>Aaron Richard Jones</b>
          </p>
          <p>
            <NavLink to="/">Bitline.io</NavLink>
          </p>
          <p>
            <b>Co-Founder / CEO</b>
          </p>
          <p>
            <b>Richard.Jones@Bitline.io</b>
          </p>
          <p>
            <a href="https://www.linkedin.com/in/richard-jones-6baab623b/">
              Linked In
            </a>
          </p> */}
        </div>
      </div>
    </section>
  );
};

export default CustodialTerms;

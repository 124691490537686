import React from "react";
import "./JobCard.scss";
import { jobDetail } from "./helper";
import { remote, time } from "../../../assets/images/images";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../../../common/ScrollToTop";

const JobCard = () => {
  const navigate = useNavigate();
    const navigateToContact = () => {
      navigate("/contact-us");
      scrollToTop();
    };
  return (
    <>
      {jobDetail?.length > 0 ? (
        jobDetail.map((item, index) => (
          <div
            className="job-card"
            key={index}
            // data-aos="fade-up"
            // data-aos-delay={`${index * 200}`}
          >
            <span className="job-cat">{item.jobCategory}</span>
            <h4>
              {item.jobTitle} <span className="short-cat">{item.shortCat}</span>
            </h4>
            <p>{item.jobDesc}</p>
            <div className="job-type-time">
              {item.jobType ? (
                <div className="content">
                  <img src={remote} alt="img" />
                  <span>{item.jobType}</span>
                </div>
              ) : (
                ""
              )}
              {item.jobTime ? (
                <div className="content">
                  <img src={time} alt="img" />
                  <span>{item.jobTime}</span>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="url">
              <p onClick={navigateToContact}>
                View job
                <span>
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.833252 9.16683L9.16659 0.833496M9.16659 0.833496H0.833252M9.16659 0.833496V9.16683"
                      stroke="#CECFD2"
                      stroke-width="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </p>
            </div>
          </div>
        ))
      ) : (
        <p>No jobs available right now.</p>
      )}
    </>
  );
};

export default JobCard;

import React from "react";
import SignupWrapper from "./SignupWrapper";

const SignUp = () => {

  return (
    <SignupWrapper />
  );
};

export default SignUp;

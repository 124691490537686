import React, { Suspense, useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import SkeletonLoader from "./components/loader";
import { Route, Routes, useLocation } from "react-router-dom";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Home from "./pages/home/Home";
import Career from "./pages/careers/Career";
import ContactPage from "./pages/contact/ContactPage";
import Login from "./pages/auth/login";
import PasswordReset from "./pages/auth/reset";
import SuccessPage from "./pages/auth/success-page";
import SignUp from "./pages/auth/signup";
import Privacy from "./pages/privacy/Privacy";
import Cookies from "./pages/cookies/Cookies";
import Terms from "./pages/terms/Terms";
import ScrollTop from "./common/ScrollTop";
import Detail from "./components/detail/Detail";
import TermsAgreement from "./pages/termsAgreement/TermsAgreement";
import { ToastContainer } from "react-toastify";
import SaleAgreement from "./pages/saleAgreement/SaleAgreement";
import DigitalAsset from "./pages/digitalAsset/DigitalAsset";
import CustodialTerms from "./pages/custodialTerms/CustodialTerms";
function App() {
  const [overlay, setOverlay] = useState(false);

  const location = useLocation();
  useEffect(() => {
    AOS.init({
      once: false,
      offset: 100,
    });
  }, []);
  const isAuthPage = [
    "/signup",
    "/login",
    "/forgot",
    "/check-email",
    "/reset-password",
    "/verify-email",
    "/two-factor",
    "/authenticator",
    "/success",
  ].includes(location.pathname);
  return (
    <>
      {isAuthPage ? (
        <Suspense fallback={<SkeletonLoader />}>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/reset-password" element={<PasswordReset />} />
            <Route path="/success" element={<SuccessPage />} />
          </Routes>
        </Suspense>
      ) : (
        <>
          <Header setOverlay={setOverlay} />
          <div className="main-content">
            <Suspense fallback={<SkeletonLoader />}>
              <ScrollTop />
              <Routes>
                <Route
                  path="/"
                  element={
                    <Suspense fallback={<SkeletonLoader />}>
                      <Home />
                    </Suspense>
                  }
                />
                <Route
                  path="/careers"
                  element={
                    <Suspense fallback={<SkeletonLoader />}>
                      <Career />
                    </Suspense>
                  }
                />
                <Route
                  path="/blog-detail/:id"
                  element={
                    <Suspense fallback={<SkeletonLoader />}>
                      <Detail />
                    </Suspense>
                  }
                />
                <Route
                  path="/contact-us"
                  element={
                    <Suspense fallback={<SkeletonLoader />}>
                      <ContactPage />
                    </Suspense>
                  }
                />
                <Route
                  path="/privacy-policy"
                  element={
                    <Suspense fallback={<SkeletonLoader />}>
                      <Privacy />
                    </Suspense>
                  }
                />
                <Route
                  path="/terms-of-use"
                  element={
                    <Suspense fallback={<SkeletonLoader />}>
                      <Terms />
                    </Suspense>
                  }
                />
                <Route
                  path="/conditional-sale-agreement"
                  element={
                    <Suspense fallback={<SkeletonLoader />}>
                      <SaleAgreement />
                    </Suspense>
                  }
                />
                <Route
                  path="/Digital-Asset-Pledge-Terms"
                  element={
                    <Suspense fallback={<SkeletonLoader />}>
                      <DigitalAsset />
                    </Suspense>
                  }
                />
                <Route
                  path="/Custodial-Services-Terms"
                  element={
                    <Suspense fallback={<SkeletonLoader />}>
                      <CustodialTerms />
                    </Suspense>
                  }
                />
                <Route
                  path="/terms-of-use-customer-conditional-sale-agreement"
                  element={
                    <Suspense fallback={<SkeletonLoader />}>
                      <TermsAgreement />
                    </Suspense>
                  }
                />
                <Route
                  path="/cookie-settings"
                  element={
                    <Suspense fallback={<SkeletonLoader />}>
                      <Cookies />
                    </Suspense>
                  }
                />
              </Routes>
            </Suspense>
          </div>
          <Footer />
          <ToastContainer />
        </>
      )}
    </>
  );
}

export default App;

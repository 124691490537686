import React from "react";
import "./CaseStudy.scss";
import Tag from "../../../../components/tags/Tag";
import { caseStudyLogo } from "../../../../assets/images/images";

const CaseStudy = () => {
  return (
    <section className="section case-study">
      <div className="container">
        <div className="header" data-aos="fade-up">
          <Tag dot text="Case Study" className="stroke" />
          <h2>Bitline Case Study</h2>
          <p>Becoming a Bitline member is both efficient and effective.</p>
        </div>
        <div className="content">
          <div className="content-logo">
            <img src={caseStudyLogo} alt="logo" />
          </div>
          <div className="content-video">
            <iframe
              data-aos="fade-up"
              src="https://www.youtube.com/embed/9hq19AgyupU"
              title="INTRODUCTING BITLINE"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
          <p data-aos="fade-up">
            Members from any state or country in the world can access casino
            chips in global casinos via a digital asset transaction in just
            minutes. Accessing casino chip limits up to $10,000,000 using
            digital assets has never been quicker or easier.
          </p>
        </div>
      </div>
    </section>
  );
};

export default CaseStudy;

import config from '../../config/config';
import { LOG_IN } from './types';
import Bitline from "../../config/Bitline"
import axios from 'axios';

export const checkType = (data) => async dispatch => {
    return Bitline.post(`${config.base_url_admin}/api/v1/publicrole/authmodule/accounttype`, data);
};

export const login = (data, type) => async dispatch => {
    function getUrl() {
        if (type == "member") {
            return config.base_url
        }
        else if (type == "admin") {
            return config.base_url_admin
        }
        else if (type == "venue") {
            return config.base_url_venue
        }
    }
    try {
        const res = await Bitline.post(`${getUrl()}/api/v1/publicrole/authmodule/login`, data);

        if (!res || res.status < 200 || res.status >= 300) {
            throw new Error(`HTTP Error: ${res.status}`);
        }

        dispatch({
            type: LOG_IN,
            payload: { ...res?.data?.details, ...data }
        })
        return res
    } catch (err) {
        dispatch({
            type: LOG_IN,
            payload: {}
        })
        throw err;
    }
};

export const tfaLogin = (data, type) => async dispatch => {
    function getUrl() {
        if (type == "member") {
            return config.base_url
        }
        else if (type == "admin") {
            return config.base_url_admin
        }
        else if (type == "venue") {
            return config.base_url_venue
        }
    }
    return Bitline.post(`${getUrl()}/api/v1/publicrole/authmodule/tfalogin`, data);
};

export const registeration = (data) => async dispatch => {
    return Bitline.post('/api/v1/publicrole/registrationmodule/registercustomeruser', data);
};

export const resendOtp = (data) => async dispatch => {
    return Bitline.post('/api/v1/publicrole/authmodule/verifyemailaddress', data);
};

export const verifyEmail = (data) => async dispatch => {
    return Bitline.post('/api/v1/publicrole/authmodule/verifyemailaddress', data);
};

export const requestQRCode = (data, type) => async dispatch => {
    function getUrl() {
        if (type == "admin") {
            return config.base_url_admin
        }
        else if (type == "venue") {
            return config.base_url_venue
        } else {
            return config.base_url
        }
    }
    return Bitline.post(`${getUrl()}/api/v1/publicrole/authmodule/requesttotpqrcode`, data);
};

export const enableTFA = (data, type) => async dispatch => {
    function getUrl() {
        if (type == "admin") {
            return config.base_url_admin
        }
        else if (type == "venue") {
            return config.base_url_venue
        } else {
            return config.base_url
        }
    }
    return Bitline.post(`${getUrl()}/api/v1/publicrole/authmodule/enabletfa`, data);
};

export const enableTFAForSMS = (data, type) => async dispatch => {
    function getUrl() {
        if (type == "admin") {
            return config.base_url_admin
        }
        else if (type == "venue") {
            return config.base_url_venue
        } else {
            return config.base_url
        }
    }
    return Bitline.post(`${getUrl()}/api/v1/publicrole/authmodule/requestsmstfacode`, data);
};

export const sendContactForm = (data) => async dispatch => {
    return axios.post(`${config.base_url_website}/form/bitline/contact-us`, data);
};

export const forgetPassword = (data, type) => async dispatch => {
    function getUrl() {
        if (type == "member") {
            return config.base_url
        }
        else if (type == "admin") {
            return config.base_url_admin
        }
        else if (type == "venue") {
            return config.base_url_venue
        }
    }
    return Bitline.post(`${getUrl()}/api/v1/publicrole/forgottenpasswordmodule/requestpasswordreset?EmailAddress=${data}`, data);
};
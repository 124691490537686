import React from "react";
import "./Tags.scss";
import { tagDot } from "../../assets/images/images";

const Tag = ({ text, dot, className, style }) => {
  return (
    <div className={`tag ${className}`} style={style}>
      {dot ? (
        <span>
          <img src={tagDot} alt="tagDot" />
        </span>
      ) : (
        ""
      )}
      {text}
    </div>
  );
};

export default Tag;

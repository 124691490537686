import React, { useState } from "react";
import styles from "./AddExtraSecurity.module.scss";
import styles2 from "../companyDetail/CompanyDetail.module.scss";
import styles3 from "../authApp/AuthApp.module.scss";
import { NavLink, useNavigate } from "react-router-dom";
import {
  authAppImg,
  signupBg,
  smsImage,
} from "../../../../assets/images/images";
import PrimaryButton from "../../../../common/primaryButton";
import * as userActions from "../../../../store/actions/user";
import { connect } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import AuthCode from "react-auth-code-input";
import PhoneInput from "react-phone-number-input";
import getErrorMessage from "../../../../utils/getErrorMessage";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Resend from "../../../../components/resend/Resend";

const AddExtraSecurity = ({
  registerFormik,
  setStep,
  requestQRCode,
  enableTFA,
  enableTFAForSMS,
  target
}) => {
  const [selected, setSelected] = useState("auth");
  const [subStep, setSubStep] = useState(1);
  const [useAuthLoading, setUseAuthLoading] = useState(false);
  const [authData, setAuthData] = useState({});
  const [error, setError] = useState("");
  const [result, setResult] = useState();
  const [authVeriLoading, setAuthVeriLoading] = useState(false);
  const [sendVeriCodeLoading, setSendVeriCodeLoading] = useState(false);
  const [smsVeriLoading, setSmsVeriLoading] = useState(false);

  const navigate = useNavigate();

  // for handle email input
  const handleOnChange = (res) => {
    setError("");
    setResult(res);
  };

  const onUseAuth = () => {
    setSubStep(2);
    setUseAuthLoading(true);
    const payload = {
      username: registerFormik?.values?.emailAddress,
      password: registerFormik?.values?.password,
    };
    requestQRCode(payload, target).then((res) => {
      setUseAuthLoading(false);
      setAuthData(res?.data?.details);
    });
  };
  const onUseSMS = () => {
    setSubStep(3);
  };
  const onVerifyAuth = () => {
    if (result?.length == 6) {
      setAuthVeriLoading(true);
      const payload = {
        password: registerFormik?.values?.password,
        phoneNumber: "",
        tfaCode: result,
        tfaType: "AuthenticatorApp",
        totpSharedSecret: authData?.sharedSecret,
        username: registerFormik?.values?.emailAddress,
      };
      enableTFA(payload, target)
        .then(() => {
          setSubStep(6);
          setAuthVeriLoading(false);
        })
        .catch((err) => {
          setError(
            getErrorMessage(err?.response?.data?.errors[0]?.messageCode)
          );
          setAuthVeriLoading(false);
        });
    }
  };
  const onSendVerificationCode = () => {
    if (registerFormik?.values?.phoneNumber) {
      setSendVeriCodeLoading(true);
      setError("");
      const payload = {
        username: registerFormik?.values?.emailAddress,
        password: registerFormik?.values?.password,
        phoneNumber: registerFormik?.values?.phoneNumber,
        bNewPhoneNumber: true,
      };
      enableTFAForSMS(payload, target)
        .then(() => {
          setSubStep(5);
          setSendVeriCodeLoading(false);
        })
        .catch((err) => {
          setError(
            getErrorMessage(err?.response?.data?.errors[0]?.messageCode)
          );
          setSendVeriCodeLoading(false);
        });
    }
  };

  const onForwardWithSelection = () => {
    if (selected == "auth") {
      onUseAuth();
    } else if (selected == "sms") {
      onUseSMS();
    }
  };
  const onResendOtp = () => {
    const payload = {
      username: registerFormik?.values?.emailAddress,
      password: registerFormik?.values?.password,
      phoneNumber: registerFormik?.values?.phoneNumber,
      bNewPhoneNumber: true,
    };
    return enableTFAForSMS(payload, target)
  };
  const onVerifySMS = () => {
    if (result?.length == 6) {
      setSmsVeriLoading(true);
      const payload = {
        password: registerFormik?.values?.password,
        phoneNumber: registerFormik?.values?.phoneNumber,
        tfaCode: result,
        tfaType: "SMS",
        totpSharedSecret: "",
        username: registerFormik?.values?.emailAddress,
      };
      enableTFA(payload, target)
        .then(() => {
          setSubStep(6);
          setSmsVeriLoading(false);
        })
        .catch((err) => {
          setError("Invalid code");
          setSmsVeriLoading(false);
        });
    }
  };

  // ui functions
  function selectOptions() {
    return (
      <>
        <div className={styles.header}>
          <img className={styles.bgImg} src={signupBg} alt="bg" />
          <NavLink to="/">
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="42"
                height="42"
                rx="9.33333"
                fill="url(#paint0_linear_1_17711)"
              />
              <rect
                x="0.466667"
                y="0.466667"
                width="41.0667"
                height="41.0667"
                rx="8.86667"
                stroke="white"
                stroke-opacity="0.13"
                stroke-width="0.933333"
              />
              <path
                d="M21.2553 29.2066L25.7001 33H16.8105L21.2553 29.2066Z"
                fill="white"
              />
              <path
                d="M22.22 22.964C22.2242 24.255 21.7662 25.5097 20.9199 26.526C20.0735 27.5423 18.8881 28.261 17.5545 28.5662C16.221 28.8714 14.8171 28.7454 13.569 28.2085C12.3209 27.6716 11.3013 26.7551 10.6745 25.6066C10.0477 24.4581 9.85016 23.1445 10.1138 21.8775C10.3774 20.6105 11.0868 19.4639 12.1277 18.6225C13.1686 17.781 14.4803 17.2937 15.8515 17.2391C17.2227 17.1844 18.5735 17.5657 19.6863 18.3213L18.3273 20.0922C17.6374 19.6237 16.7999 19.3873 15.9497 19.4212C15.0996 19.4551 14.2863 19.7572 13.641 20.2789C12.9956 20.8006 12.5558 21.5115 12.3924 22.297C12.2289 23.0826 12.3514 23.897 12.74 24.6091C13.1286 25.3211 13.7608 25.8894 14.5346 26.2223C15.3084 26.5552 16.1788 26.6333 17.0056 26.444C17.8324 26.2548 18.5674 25.8092 19.0921 25.1791C19.6169 24.549 19.9008 23.7711 19.8982 22.9706L22.22 22.964Z"
                fill="white"
              />
              <path
                d="M15.2471 15.8855C15.024 14.8465 15.1107 13.7697 15.4976 12.7741C15.8845 11.7786 16.5565 10.9031 17.4394 10.2444C18.3223 9.58573 19.3815 9.16954 20.5002 9.04185C21.6188 8.91417 22.7531 9.07996 23.7778 9.52091C24.8025 9.96185 25.6777 10.6608 26.3064 11.5404C26.9352 12.42 27.2932 13.446 27.3407 14.5051C27.3883 15.5641 27.1236 16.6149 26.5759 17.5413C26.0282 18.4676 25.2188 19.2334 24.2373 19.7541L23.0969 17.8516C23.7054 17.5288 24.2072 17.054 24.5468 16.4797C24.8864 15.9053 25.0505 15.2538 25.021 14.5972C24.9915 13.9406 24.7696 13.3045 24.3797 12.7591C23.9899 12.2138 23.4473 11.7804 22.812 11.5071C22.1767 11.2337 21.4734 11.1309 20.7798 11.21C20.0863 11.2892 19.4295 11.5472 18.8822 11.9556C18.3348 12.364 17.9181 12.9068 17.6783 13.5241C17.4384 14.1413 17.3846 14.8089 17.5229 15.4531L15.2471 15.8855Z"
                fill="white"
              />
              <path
                d="M22.3324 18.2913C23.2485 17.6741 24.3282 17.3072 25.4521 17.2312C26.576 17.1553 27.7004 17.3732 28.7009 17.8608C29.7014 18.3484 30.539 19.0869 31.1212 19.9944C31.7035 20.9019 32.0075 21.9432 31.9999 23.0032C31.9922 24.0632 31.6731 25.1005 31.0778 26.0005C30.4825 26.9005 29.6342 27.6281 28.6267 28.1029C27.6193 28.5777 26.4919 28.7811 25.3691 28.6908C24.2464 28.6004 23.1721 28.2197 22.265 27.5909L23.6425 25.8327C24.2049 26.2226 24.871 26.4586 25.5671 26.5146C26.2631 26.5706 26.9621 26.4445 27.5868 26.1501C28.2114 25.8558 28.7373 25.4047 29.1064 24.8467C29.4755 24.2887 29.6734 23.6455 29.6781 22.9883C29.6829 22.3312 29.4943 21.6855 29.1334 21.1229C28.7724 20.5602 28.2531 20.1024 27.6327 19.8C27.0124 19.4977 26.3153 19.3626 25.6185 19.4097C24.9217 19.4568 24.2522 19.6843 23.6843 20.0669L22.3324 18.2913Z"
                fill="white"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1_17711"
                  x1="42"
                  y1="42"
                  x2="-378.5"
                  y2="-288"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#4556F3" />
                  <stop offset="1" stop-color="white" />
                </linearGradient>
              </defs>
            </svg>
          </NavLink>
          <h3>Add Extra Security</h3>
          <p>Secure your auth by adding two step verification</p>
        </div>
        <form action="">
          <div className={styles.authSelectors}>
            <div
              className={`${styles.selector} ${selected === "auth" ? styles.selected : ""
                }`}
              onClick={() => setSelected("auth")}
            >
              <div className={styles.authenticatorSelectorImg}>
                <img src={authAppImg} alt="authImg" />
              </div>
              <p className={styles.authenticatorSelectorText}>Authenticator</p>
            </div>
            <div
              className={`${styles.selector} ${selected === "sms" ? styles.selected : ""
                }`}
              onClick={() => setSelected("sms")}
            >
              <div className={styles.authenticatorSelectorImg}>
                <img src={smsImage} alt="authImg" />
              </div>
              <p className={styles.authenticatorSelectorText}>SMS</p>
            </div>
          </div>
          <div className={styles.formBtns}>
            <PrimaryButton
              handleClick={onForwardWithSelection}
              primaryBtnText="Continue"
            />
          </div>
        </form>
      </>
    );
  }
  function AuthenticatorApp() {
    if (useAuthLoading) {
      return (
        <div className={styles.skeletonLoader}>
          <Skeleton
            className={`${styles.skeletonLoaderSpan} ${styles.skeletonLoaderSpanFirst}`}
            baseColor="#282525"
            highlightColor="#333333"
          />
          <Skeleton
            className={`${styles.skeletonLoaderSpan}`}
            baseColor="#282525"
            highlightColor="#333333"
          />
          <Skeleton
            className={`${styles.skeletonLoaderSpan}`}
            baseColor="#282525"
            highlightColor="#333333"
          />
        </div>
      );
    }
    return (
      <div className={styles3.authApp}>
        <div className={styles3.header}>
          <h3>Two-Factor Authentication</h3>
          <p>
            To setup 2FA scan the QR code below using your authenticator app
          </p>
        </div>
        <form action="">
          <div
            style={{
              height: "auto",
              margin: "0 auto",
              width: "100%",
            }}
          >
            <img
              src={"data:image/png;base64," + authData?.qrCode}
              style={{ width: 120, objectFit: "contain" }}
              alt=""
            />
            <p className={styles.qrText}>{authData?.sharedSecret}</p>
            <CopyToClipboard text={authData?.sharedSecret}>
              <span className={styles.qrText}>Copy to clipboard</span>
            </CopyToClipboard>
          </div>
          <div className={styles.formBtns}>
            <PrimaryButton
              primaryBtnText="Continue"
              handleClick={() => {
                setError("");
                setSubStep(4);
              }}
            />
            <PrimaryButton
              handleClick={() => setSubStep(3)}
              primaryBtnText="Use SMS instead"
              className="dark"
            />
          </div>
        </form>
      </div>
    );
  }
  function authenticatorVerification() {
    return (
      <div className={styles3.authApp}>
        <div className={styles3.header}>
          <h3>Two-Factor Authentication</h3>
          <p>Enter the code from your authentication app</p>
        </div>
        <form action="">
          <h5>your verification code</h5>
          <div className={styles.verificationCode}>
            <AuthCode
              containerClassName={styles.otpCode}
              onChange={(res) => handleOnChange(res)}
              allowedCharacters="numeric"
              inputClassName={error && `${styles.errorInput}`}
              placeholder="•"
            />
          </div>
          {error && <p className={styles.error}>{error}</p>}
          <div className={styles.formBtns}>
            <PrimaryButton
              isLoading={authVeriLoading}
              handleClick={onVerifyAuth}
              primaryBtnText="Continue"
            />
            <PrimaryButton
              handleClick={() => setSubStep(3)}
              primaryBtnText="Use SMS instead"
              className="dark"
            />
            <PrimaryButton
              handleClick={() => setSubStep(2)}
              primaryBtnText="back"
              className="dark"
            />
          </div>
        </form>
      </div>
    );
  }
  function SMS() {
    return (
      <div className={styles3.authApp}>
        <div className={styles3.header}>
          <h3>Two-Factor Authentication</h3>
          <p>Enter your phone number to receive your code</p>
        </div>
        <form action="">
          <label
            className={styles.fieldLabel}
            htmlFor=""
            style={{ color: "white" }}
          >
            Phone number*
          </label>
          <div className={styles2.selectPhone}>
            <PhoneInput
              defaultCountry="US"
              id="phoneInput"
              className={styles2.phoneInput}
              name="phoneNumber"
              ref={registerFormik.getFieldProps("phoneNumber").ref}
              value={registerFormik.values.phoneNumber}
              onChange={(value) =>
                registerFormik.setFieldValue("phoneNumber", value)
              }
            />
            {registerFormik.touched.phoneNumber &&
              registerFormik.errors.phoneNumber && (
                <p style={{ color: "white" }}>
                  {registerFormik.errors.phoneNumber}
                </p>
              )}
          </div>
          <div className={styles.formBtns}>
            <PrimaryButton
              isLoading={sendVeriCodeLoading}
              handleClick={onSendVerificationCode}
              primaryBtnText="Continue"
            />
            <PrimaryButton
              handleClick={onUseAuth}
              primaryBtnText="Use Authy instead"
              className="dark"
            />
          </div>
        </form>
      </div>
    );
  }
  function SMSVerification() {
    return (
      <div className={styles3.authApp}>
        <div className={styles3.header}>
          <h3>Two-Factor Authentication</h3>
          <p>Enter your code sent to your phone number</p>
        </div>
        <form action="">
          <h5>your verification code</h5>
          <div className={styles.verificationCode}>
            <AuthCode
              containerClassName={styles.otpCode}
              onChange={(res) => handleOnChange(res)}
              allowedCharacters="numeric"
              inputClassName={error && `${styles.errorInput}`}
              placeholder="•"
            />
          </div>
          {error && <p className={styles.error}>{error}</p>}

          <h6>
            Didn't get a code?{" "}
            <Resend onResend={onResendOtp} />
          </h6>

          <div className={styles.formBtns}>
            <PrimaryButton
              isLoading={smsVeriLoading}
              handleClick={onVerifySMS}
              primaryBtnText="Continue"
            />
            <PrimaryButton
              handleClick={onUseAuth}
              primaryBtnText="Use Authy instead"
              className="dark"
            />
            <PrimaryButton
              handleClick={() => setSubStep(3)}
              primaryBtnText="back"
              className="dark"
            />
          </div>
        </form>
      </div>
    );
  }

  // stepper
  function renderSubStep() {
    if (subStep == 1) {
      return selectOptions();
    } else if (subStep == 2) {
      return AuthenticatorApp();
    } else if (subStep == 3) {
      return SMS();
    } else if (subStep == 4) {
      return authenticatorVerification();
    } else if (subStep == 5) {
      return SMSVerification();
    } else if (subStep == 6) {
      return (
        <>
          <div className={styles.header}>
            <img className={styles.bgImg} src={signupBg} alt="bg" />
            <NavLink to="/">
              <svg
                width="42"
                height="42"
                viewBox="0 0 42 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="42"
                  height="42"
                  rx="9.33333"
                  fill="url(#paint0_linear_1_17711)"
                />
                <rect
                  x="0.466667"
                  y="0.466667"
                  width="41.0667"
                  height="41.0667"
                  rx="8.86667"
                  stroke="white"
                  stroke-opacity="0.13"
                  stroke-width="0.933333"
                />
                <path
                  d="M21.2553 29.2066L25.7001 33H16.8105L21.2553 29.2066Z"
                  fill="white"
                />
                <path
                  d="M22.22 22.964C22.2242 24.255 21.7662 25.5097 20.9199 26.526C20.0735 27.5423 18.8881 28.261 17.5545 28.5662C16.221 28.8714 14.8171 28.7454 13.569 28.2085C12.3209 27.6716 11.3013 26.7551 10.6745 25.6066C10.0477 24.4581 9.85016 23.1445 10.1138 21.8775C10.3774 20.6105 11.0868 19.4639 12.1277 18.6225C13.1686 17.781 14.4803 17.2937 15.8515 17.2391C17.2227 17.1844 18.5735 17.5657 19.6863 18.3213L18.3273 20.0922C17.6374 19.6237 16.7999 19.3873 15.9497 19.4212C15.0996 19.4551 14.2863 19.7572 13.641 20.2789C12.9956 20.8006 12.5558 21.5115 12.3924 22.297C12.2289 23.0826 12.3514 23.897 12.74 24.6091C13.1286 25.3211 13.7608 25.8894 14.5346 26.2223C15.3084 26.5552 16.1788 26.6333 17.0056 26.444C17.8324 26.2548 18.5674 25.8092 19.0921 25.1791C19.6169 24.549 19.9008 23.7711 19.8982 22.9706L22.22 22.964Z"
                  fill="white"
                />
                <path
                  d="M15.2471 15.8855C15.024 14.8465 15.1107 13.7697 15.4976 12.7741C15.8845 11.7786 16.5565 10.9031 17.4394 10.2444C18.3223 9.58573 19.3815 9.16954 20.5002 9.04185C21.6188 8.91417 22.7531 9.07996 23.7778 9.52091C24.8025 9.96185 25.6777 10.6608 26.3064 11.5404C26.9352 12.42 27.2932 13.446 27.3407 14.5051C27.3883 15.5641 27.1236 16.6149 26.5759 17.5413C26.0282 18.4676 25.2188 19.2334 24.2373 19.7541L23.0969 17.8516C23.7054 17.5288 24.2072 17.054 24.5468 16.4797C24.8864 15.9053 25.0505 15.2538 25.021 14.5972C24.9915 13.9406 24.7696 13.3045 24.3797 12.7591C23.9899 12.2138 23.4473 11.7804 22.812 11.5071C22.1767 11.2337 21.4734 11.1309 20.7798 11.21C20.0863 11.2892 19.4295 11.5472 18.8822 11.9556C18.3348 12.364 17.9181 12.9068 17.6783 13.5241C17.4384 14.1413 17.3846 14.8089 17.5229 15.4531L15.2471 15.8855Z"
                  fill="white"
                />
                <path
                  d="M22.3324 18.2913C23.2485 17.6741 24.3282 17.3072 25.4521 17.2312C26.576 17.1553 27.7004 17.3732 28.7009 17.8608C29.7014 18.3484 30.539 19.0869 31.1212 19.9944C31.7035 20.9019 32.0075 21.9432 31.9999 23.0032C31.9922 24.0632 31.6731 25.1005 31.0778 26.0005C30.4825 26.9005 29.6342 27.6281 28.6267 28.1029C27.6193 28.5777 26.4919 28.7811 25.3691 28.6908C24.2464 28.6004 23.1721 28.2197 22.265 27.5909L23.6425 25.8327C24.2049 26.2226 24.871 26.4586 25.5671 26.5146C26.2631 26.5706 26.9621 26.4445 27.5868 26.1501C28.2114 25.8558 28.7373 25.4047 29.1064 24.8467C29.4755 24.2887 29.6734 23.6455 29.6781 22.9883C29.6829 22.3312 29.4943 21.6855 29.1334 21.1229C28.7724 20.5602 28.2531 20.1024 27.6327 19.8C27.0124 19.4977 26.3153 19.3626 25.6185 19.4097C24.9217 19.4568 24.2522 19.6843 23.6843 20.0669L22.3324 18.2913Z"
                  fill="white"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1_17711"
                    x1="42"
                    y1="42"
                    x2="-378.5"
                    y2="-288"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#4556F3" />
                    <stop offset="1" stop-color="white" />
                  </linearGradient>
                </defs>
              </svg>
            </NavLink>
            <h3>Congratulations!</h3>
            <p>Your registration was successful.</p>
          </div>
          <form action="">
            <div className={styles.formBtns}>
              <PrimaryButton
                handleClick={() => navigate("/login")}
                primaryBtnText="Go to Sign In"
              />
            </div>
          </form>
        </>
      );
    }
  }
  return <div className={styles.infoDetail}>{renderSubStep()}</div>;
};

export default connect(null, userActions)(AddExtraSecurity);

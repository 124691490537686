import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./FeaturedSlider.module.scss";
import {
  abc,
  AP,
  cbs,
  digJournal,
  foxNews,
  nbc,
} from "../../../../assets/images/images";

const FeaturedSlider = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  return (
    <div className={`${styles.sliderSection}`}>
      <div className={`container ${styles.featuredSlider}`}>
        <h3>As seen on</h3>
        <Slider {...settings} className={styles.slider}>
          <div className={styles.sliderImg}>
            <img src={cbs} alt="img" />
          </div>
          <div className={`${styles.sliderImg} ${styles.digJournal}`}>
            <img src={digJournal} alt="img" />
          </div>
          <div className={styles.sliderImg}>
            <img src={AP} alt="img" />
          </div>
          <div className={styles.sliderImg}>
            <img src={foxNews} alt="img" />
          </div>
          <div className={styles.sliderImg}>
            <img src={nbc} alt="img" />
          </div>

          <div className={styles.sliderImg}>
            <img src={abc} alt="img" />
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default FeaturedSlider;

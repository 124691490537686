import React from "react";
import "./Privacy.scss";
import { NavLink } from "react-router-dom";

const Privacy = () => {
  return (
    <section className="section privacy-hero">
      <div className="container">
        <div className="content">
          {/* <span>Current as of 20 Jan 2022</span> */}
          <h2>Privacy Policy</h2>
          {/* <p>
            We use some cookies to help improve your experience on this website.
            Here's some more info about what we do with information we collect.
          </p> */}
        </div>
        <div className="desc">
          <h5>Summary</h5>
          <p>
            Detailed information on our privacy practices, appears below this
            summary.
          </p>
          <h4>What type of information we collect</h4>
          <ul>
            <li>Your activity using the Services</li>
            <li>
              Personal and 'Know Your Customer' information provided on your
              account
            </li>
            <li>Information from your communications with us</li>
            <li>Inquiries, bugs and reports you send to us</li>
            <li>
              Device information, including IP address and geolocation data
            </li>
            <li>How we use the information</li>
            <li>
              BitLine collects and uses the information to provide you with an
              online platform, aimed at allowing you to purchase and/or sell
              guarantees for virtual currency in an easy, fast and safe way, as
              well as making your experience of using the platform smoother and
              better. BitLine may further use your Personal Information to send
              you marketing materials regarding the Services.
            </li>
          </ul>
          <h4>With whom we share the information</h4>
          <p>
            BitLine does not rent out or sell your Personal Information. BitLine
            shares Personal Information with third parties only in connection
            with the Services provided to you, and only with selected trusted
            third-party service providers. BitLine shares with such service
            providers the minimum information necessary. BitLine may also share
            Personal Information with law enforcement agencies or arbitrators
            for legal or compliance reasons or in the event of a dispute.
          </p>
          <h4>How we protect the information</h4>
          <p>
            BitLine employs industry standard practices to ensure the safety of
            your information and prevent unauthorized use of any such
            information.
          </p>
          <h4>Minors</h4>
          <p>
            Individuals under the age of 21 are not allowed to use the Services.
            If you are under the age of 21, you should not provide any Personal
            Information to us or use or try to use the Services.
          </p>
          <h4>Cookies</h4>
          <p>
            You have the right to decide whether to accept cookies. You can see
            which cookies we use and exercise your acceptance preferences of
            cookies served on our Site by clicking the Cookie Settings link
            found at the bottom of the page.
          </p>
          <h5>Detailed Privacy Policy</h5>
          <h4>1. Introduction</h4>
          <p>
            Bitline and/or parent company Ibanera Ltd. (both companies, jointly
            and severally, shall be referred to as <b>"us"</b>, <b>"we"</b> or
            <b>"Company"</b>) respect the privacy of our users (each,{" "}
            <b>"you"</b> or <b>User</b>) and are committed to protecting the
            privacy of Users who access, visit, use or engage with our website
            or any other online services we provide (collectively: the
            <b>Services</b>).
            <br />
          </p>
          <p>
            The Company has prepared this Privacy Policy to outline its
            practices with respect to collecting, using and disclosing your
            information when you use the Services.
          </p>
          <p>
            We encourage you to read the Privacy Policy carefully and use it to
            make informed decisions. By using the Services, you agree to the
            terms of this Privacy Policy and your continued use of the Services
            constitutes your ongoing agreement to the Privacy Policy.
          </p>
          <br />
          <p>
            The Privacy Policy is a part of the{" "}
            <NavLink to={"/terms-of-use"}>Terms of Service</NavLink> and is
            incorporated herein by reference.
          </p>
          <br />
          <p>In this Privacy Policy you will read about, among other things:</p>
          <br />
          <ul className="dot-list">
            <li>
              What type of information we collect and our legal basis for its
              collection
            </li>
            <li>How we use the information</li>
            <li>With whom we share the information and for what purpose</li>
            <li>International transfer</li>
            <li>Your privacy rights</li>
            <li>For how long we retain the information</li>
            <li>How we protect your information</li>
            <li>Marketing</li>
            <li>Minors</li>
            <li>How to contact us</li>
          </ul>
          <br />
          <p>
            <b>"You"</b> and <b>"Your"</b> refers to you, the person accessing
            the Website and which by doing so, accepts this Privacy Policy. Any
            use of the above terminology or other words in the singular, plural,
            capitalization are taken as interchangeable and therefore as
            referring to the same.
          </p>
          <h4>
            1. What type of information we collect and our legal basis for its
            collection
          </h4>
          <p>
            We may collect two types of data and information from our Users.
          </p>
          <br />
          <p>
            The first type of information is un-identified and non-identifiable
            information pertaining to you, which may be made available or
            gathered via your use of the Services (
            <b>"Non-personal Information"</b>). We are not aware of the identity
            from which the Non-personal Information is collected. Non-personal
            Information which is collected may include your aggregated usage
            information and technical information transmitted by your device,
            including certain software and hardware information about your
            device (e.g., the device you use, the type of browser and operating
            system your device uses, language preference, access time and the
            website’s domain name from which you linked to the Services, etc.),
            in order to enhance the functionality of the Services. We may also
            collect information about your activity on the Services (e.g.,
            clicks, actions, online browsing, etc.).
          </p>
          <p>
            The second type of information is individually identifiable
            information, namely information that identifies an individual or may
            with reasonable effort identify an individual (
            <b>"Personal Information"</b>). Such information may include:
          </p>
          <br />
          <p>
            <b>Account Information:</b> When you sign-up and register to the
            Services, you will be asked to provide us certain details about
            yourself. You may register to the Services directly through our
            website.
          </p>
          <p>
            <b>Registering through our website:</b> When you register to the
            Services through our website, we may collect from you the following
            information: full name, email address, phone number, full home
            address (country, state/province, zip code, city, street, house
            number), gender, date of birth, ID information (issuing country,
            number, expiry date, etc.), images of identification documents,
            images of proof-of-residence (utility bills, bank/credit card
            statements, government-issued letters), images of yourself (selfie
            photos), information required for our compliance with regulatory
            Know Your Customer requirements (e.g., contents of KYC Questionnaire
            and Service Applicant Declaration Form [estimated amount of intended
            activity, purpose of buying virtual currency, source of the funds
            used to buy virtual currency, employment status, profession and job
            title or position, field of activity of employing company or own
            business, name of employing company or own business, URL to the
            website of employing company or own business, signature sample,
            ultimate beneficiary of purchased virtual currency], etc.), as well
            as any other information you agreed to share with us.
          </p>
          <p>
            <b>Voluntarily Provided Information:</b> We may collect information
            which you provide to us voluntarily. For instance, when you share
            additional information about yourself through your use of the
            Services. We may also collect the feedback, suggestions, complaints
            and reports which you send to us. Please note that we may also
            collect complaints about you from other Users, which may include
            your Personal Information.
          </p>
          <p>
            <b>Communication Information:</b> We may collect information from
            your communications with us. For example, when you communicate with
            us, or respond to communications from us, via email, chat, audio or
            video calls (<b>Communications</b>). We may record and keep all the
            Communications.
          </p>
          <p>
            <b>Device Information:</b> We may collect Personal Information from
            your device. Such information may include geolocation data, IP
            address, unique identifiers, as well as other information which
            relates to your activity through the Services.
          </p>
          <p>
            For the avoidance of doubt, if we combine Personal Information with
            Non-personal Information, the combined information will be treated
            as Personal Information as long as it remains combined.
          </p>
          <p>
            Processing of Personal Information is necessary for the performance
            of our contractual obligations towards you and providing you with
            our services, to protect our legitimate interests and to comply with
            our legal obligations.
          </p>
          <h4>3. How we use the information</h4>
          <p>
            We use and share Personal Information in the manners described in
            this Privacy Policy. In addition to the purposes listed above, the
            information we collect, which may include your Personal Information,
            is used for the following purposes:
          </p>
          <br />
          <ul className="dot-list">
            <li>To set up your account and to provide the Services;</li>
            <li>
              To identify and authenticate your access to certain features of
              the Services;
            </li>
            <li>
              To authenticate your identity for the purpose of compliance with
              regulatory Know Your Customer requirements;
            </li>
            <li>
              To communicate with you and to keep you informed of our latest
              updates;
            </li>
            <li>To market our website and the Services;</li>
            <li>
              To perform research or to conduct analytics in order to improve
              and customize the Services to your needs and interests;
            </li>
            <li>
              To support and troubleshoot the Services and to respond to your
              queries;
            </li>
            <li>
              To investigate and resolve disputes in connection with your use of
              the Services;
            </li>
            <li>
              To detect and prevent fraudulent and illegal activity or any other
              type of activity that may jeopardize or negatively affect the
              integrity of the Services; and
            </li>
            <br />
            <p>
              To investigate violations and enforce our policies, and as
              required by law, regulation or other governmental authority, or to
              comply with a subpoena or similar legal process or respond to a
              government request.
            </p>
          </ul>
          <h4>4. With whom we share the information and for what purpose</h4>
          <p>
            We do not rent, sell, or share your Personal Information with third
            parties except as described in this Privacy Policy.
          </p>
          <br />
          <p>
            We may share Personal Information with the following recipients: (i)
            our subsidiaries; (ii) affiliated companies; (iii) subcontractors
            and other third-party service providers; (iv) auditors or advisers
            of our business processes; and (v) any potential purchasers or
            investors in the Company.
          </p>
          <br />
          <p>
            In addition to the purposes listed in this Privacy Policy, we may
            share Personal Information with our recipients for any of the
            following purposes: (i) storing or processing Personal Information
            on our behalf (e.g., cloud computing service providers); (ii)
            processing such information to assist us with our business
            operations; (iii) performing research, technical diagnostics,
            personalization and analytics; and (iv(iv) identity verification and
            fraud prevention services.
          </p>
          <br />
          <p>
            We may also disclose Personal Information or any information you
            submitted via the Services if we have a good faith belief that
            disclosure of such information is helpful or reasonably necessary
            to: (i) comply with any applicable law, regulation, legal process or
            governmental request; (ii) enforce our policies, including
            investigations of potential violations thereof; (iii) investigate,
            detect, prevent, or take action regarding illegal activities or
            other wrongdoing, suspected fraud or security issues; (iv) to
            establish or exercise our rights to defend against legal claims; (v)
            prevent harm to the rights, property or safety of us, our
            affiliates, our Users, yourself or any third-party; (vi) for the
            purpose of collaborating with law enforcement agencies; and (vii) in
            case we find it necessary in order to enforce intellectual property
            or other legal rights.
          </p>
          <br />
          <p>
            You acknowledge that third-party service providers may use your
            Personal Information or any information you submitted via the
            Services for analytics purposes, while ensuring that their
            processing of such information only results in anonymized and
            aggregated data.
          </p>
          <h4>5. Third-party collection of information</h4>
          <p>
            Our policy only addresses the use and disclosure of information we
            collect from you. To the extent that you disclose your information
            to other parties via the Services (e.g., by clicking on a link to
            any other website or location) or via other sites throughout the
            Internet, different rules may apply to their use or disclosure of
            the information you disclose to them.
          </p>
          <br />
          <p>
            You acknowledge that we are not responsible for the products,
            services, or descriptions of products or services that you receive
            from third-party sites or to the content or privacy practices of
            those sites and that this Privacy Policy does not apply to any such
            third-party products and services. You are knowingly and voluntarily
            assuming all risks of using third-party sites to purchase products
            and services. You agree that we shall have no liability whatsoever
            with respect to such third-party sites and your usage of them.
          </p>
          <h4>6. International transfer</h4>
          <p>
            Since we operate globally, it may be necessary to transfer data,
            including Personal Information, to countries outside USA. The data
            protection and other laws of these countries may not be as
            comprehensive as those in USA − in these instances we will take
            steps to ensure that a similar level of protection is given to
            Personal Information, including through adequate contractual
            measures.
          </p>
          <h4>7. Your privacy rights</h4>
          <p>
            We respect your privacy rights and therefore you may contact us at
            any time and request: (i) to access, delete, change or update any
            Personal Information relating to you (for example, if you believe
            that your Personal Information is incorrect, you may ask to have it
            corrected or deleted); or (ii) that we cease any further use of your
            Personal Information (for example, you may ask that we stop using or
            sharing your Personal Information with third-parties) or that we
            remove your Personal Information (subject to any other legal
            obligation that may require us to keep the information).
          </p>
          <br />
          <p>
            However, please note that the exercise of these rights is not
            absolute and may be subject to other regulatory requirements or our
            legitimate interests.
          </p>
          <br />
          <p>
            If you wish to raise a complaint on how we have handled your
            Personal Information, please contact us directly at
            support@ibanera.com.
          </p>
          <br />
          <p>
            If you are not satisfied with our response or believe we are
            collecting or processing your Personal Information in discordance
            with the laws, you can file a complaint with the applicable data
            protection authority.
          </p>
          <h4>8. For how long we retain the information</h4>
          <p>
            We retain the information we collect for as long as needed to
            provide the Services (including aspects of risk management) and to
            comply with our legal and regulatory obligations, resolve disputes,
            and enforce our agreements (unless we are instructed otherwise).
            Retention periods will be determined taking into account the type of
            information that is collected and the purpose for which it is
            collected, bearing in mind the requirements applicable to the
            situation and the need to destroy outdated, unused information at
            the earliest reasonable time. Under applicable regulations, we will
            keep records containing account opening documents, communications,
            and anything else as required by applicable laws and regulations.
          </p>
          <br />
          <p>
            We may rectify, replenish, or remove incomplete or inaccurate
            information at any time and at our own discretion.
          </p>
          <h4>9. How we protect your information</h4>
          <p>
            We take great care of implementing and maintaining the security of
            the Services and your information. We aspire to continuously employ
            industry standard procedures and policies to ensure the safety of
            your information and prevent unauthorized use of any such
            information. Although we take reasonable steps to safeguard
            information, we cannot be responsible for the acts of those who gain
            unauthorized access or abuse the Services, and we make no warranty,
            express, implied, or otherwise, that we will manage to prevent such
            access.
          </p>
          <br />
          <p>
            If you feel that your privacy was treated in discordance with our
            policy, or if any person attempted to abuse the Services or acted in
            an inappropriate manner, please contact us directly at
            support@ibanera.com.
          </p>
          <h4>10. Marketing</h4>
          <p>
            Your Personal Information, such as your full name, email address,
            etc., may be used by us, or by our third-party subcontractors to
            provide you with promotional materials, concerning our Services.
          </p>
          <br />
          <p>
            Out of respect to your right to privacy, within such marketing
            materials, we provide you with means to decline receiving further
            marketing offers from us. In addition, at any time, you may request
            to unsubscribe and discontinue receiving marketing offers by sending
            us a blank message with the word “remove” to support@ibanera.com.
          </p>
          <br />
          <p>
            Please note that even if you unsubscribe from our marketing mailing
            list, we may continue to send you service-related updates and
            notifications.
          </p>
          <h4>11. Corporate transaction</h4>
          <p>
            We may share information, including Personal Information, in the
            event of a corporate transaction (e.g., the sale of a substantial
            part of our business, a merger, consolidation of assets, the sale of
            an asset, or transfer in the operation thereof) of the Company. In
            the event of the above, the acquiring company or transferee will
            assume the rights and obligations as described in this Privacy
            Policy.
          </p>
          <h4>12. Minors</h4>
          <p>
            The Services are not designated to individuals under the age of 21.
            If you are under 21 years old, you should not use the Services or
            provide any Personal Information to us.
          </p>
          <br />
          <p>
            We reserve the right to access and verify any Personal Information
            collected from you. In the event that we become aware that an
            individual under the age of 21 has shared any of their information,
            we will discard such information. If you have any reason to believe
            that a minor has shared any information with us, please contact us
            at support@ibanera.com.
          </p>
          <h4>13. Cookies</h4>
          <p>
            We use cookies when you visit our website located at
            www.bitlineapp.com or any other websites, pages, features, or
            content we own or operate (collectively, the <b>"Site"</b>) to
            operate and administer the Site, conduct analytics and improve the
            Site and your experience on it. For more information on our use of
            cookies, please see the Privacy Preference Center on our Site (by
            clicking Cookie Settings link found at the bottom of the page).
          </p>
          <h4>14. Update or amendments to the Privacy Policy</h4>
          <p>
            We may revise this Privacy Policy from time to time, at our sole
            discretion, and the most current version will always be posted on
            our website (as reflected in the “Last Revised” section). In the
            event of a material change to the Privacy Policy, we may notify you
            through the Services or via email. We encourage you to review this
            Privacy Policy regularly for any changes.
          </p>
          <br />
          <p>
            Your continued use of the Services, following the notification of
            such amendments, constitutes your acknowledgment and consent of such
            amendments to the Privacy Policy and your agreement to be bound by
            the terms of such amendments.
          </p>
          <h4>15. How to contact us</h4>
          <p>
            If you have any general questions regarding the Services or the
            information that we collect about you and how we use it, please
            contact us at support@ibanera.com.
          </p>
          <h4>16. Information about us</h4>
          <p>Our details are as follows:</p>
          <br />
          <p>BitLine</p>
          <p>78 SW 7th St 7-118</p>
          <p>Miami, FL 33130, United States</p>
          <p>Phone: (855) 934-6636</p>
        </div>
      </div>
    </section>
  );
};

export default Privacy;

import React from "react";
import "./Cookies.scss";

const Cookies = () => {
  return (
    <section className="section cookies-hero">
      <div className="container">
        <div className="content">
          {/* <span>Current as of 20 Jan 2022</span> */}
          <h2>Cookie Policy</h2>
          {/* <p>
            We use some cookies to help improve your experience on this website.
            Here's some more info about what we do with information we collect.
          </p> */}
        </div>
        <div className="desc">
          <h4>Your Privacy</h4>
          <p>
            When you visit any web site, it may store or retrieve information on
            your browser, mostly in the form of cookies. This information might
            be about you, your preferences or your device and is mostly used to
            make the site work as you expect it to. The information does not
            usually directly identify you, but it can give you a more
            personalized web experience. Because we respect your right to
            privacy, you can choose not to allow some types of cookies. Click on
            the different category headings to find out more and change our
            default settings. However, blocking some types of cookies may impact
            your experience of the site and the services we are able to offer.
          </p>
          <br />
          <h4>Strictly Necessary Cookies</h4>
          <p>
            These cookies are necessary for the website to function and cannot
            be switched off in our systems. They are usually only set in
            response to actions made by you which amount to a request for
            services, such as setting your privacy preferences, logging in or
            filling in forms. You can set your browser to block or alert you
            about these cookies, but some parts of the site will not then work.
            These cookies do not store any personally identifiable information.{" "}
          </p>
          <br />
          <h4>Performance Cookies</h4>
          <p>
            These cookies allow us to count visits and traffic sources so we can
            measure and improve the performance of our site. They help us to
            know which pages are the most and least popular and see how visitors
            move around the site. All information these cookies collect is
            aggregated and therefore anonymous. If you do not allow these
            cookies we will not know when you have visited our site, and will
            not be able to monitor its performance.
          </p>
          <br />
          <h4>Functional Cookies</h4>
          <p>
            These cookies enable the website to provide enhanced functionality
            and personalisation. They may be set by us or by third party
            providers whose services we have added to our pages. If you do not
            allow these cookies then some or all of these services may not
            function properly.
          </p>
          <br />
          <h4>Targeting Cookies</h4>
          <p>
            These cookies may be set through our site by our advertising
            partners. They may be used by those companies to build a profile of
            your interests and show you relevant adverts on other sites. They do
            not store directly personal information, but are based on uniquely
            identifying your browser and internet device. If you do not allow
            these cookies, you will experience less targeted advertising.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Cookies;

import React, { useEffect, useState } from "react";
import styles from "./Signup.module.scss";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import PersonalDetail from "./personalDetail/PersonalDetail";
import CompanyDetail from "./companyDetail/CompanyDetail";
import VerificationCode from "./verificationCode/VerificationCode";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import * as userActions from "../../../store/actions/user"
import { connect } from "react-redux"
import AddExtraSecurity from "./addExtraSecurity/AddExtraSecurity";

const personalDetailSchema = Yup.object({
  firstName: Yup.string()
    .required('First name address is required'),
  lastName: Yup.string()
    .required('Last name address is required'),
  taxId: Yup.string()
    .required('Tax ID address is required'),
  middleNames: Yup.string(),
  nameConfirmed: Yup.boolean()
    .oneOf([true], 'Please confirm this name matches your ID')
});

const validationSchema = Yup.object({
  emailAddress: Yup.string()
    .email('Email address is invalid')
    .required('Email address is required'),
  middleNames: Yup.string(),
  emailVerificationCode: Yup.string().default(""),
  phoneNumber: Yup.string()
    .required('Phone address is required'),
  password: Yup.string()
    .min(8, 'Field character count is invalid')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .min(8, 'Field character count is invalid')
    .required('Password is required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

const SignupWrapper = ({ registeration, user }) => {
  const [step, setStep] = useState(1)
  const [btnLoading, setBtnLoading] = useState(false)
  const [errors, setErrors] = useState()
  const [searchParams] = useSearchParams();
  const navigate = useNavigate()

  useEffect(() => {
    if (searchParams.get("step")) {
      setStep(searchParams.get("step"))
      if (user?.password && user?.username) {
        registerFormik.setFieldValue("password", user?.password);
        registerFormik.setFieldValue("emailAddress", user?.username);
      } else {
        navigate('/')
      }

    }
  }, [])

  const registerFormik = useFormik({
    initialValues: {
      emailAddress: '',
      firstName: '',
      lastName: '',
      middleNames: '',
      taxId: '',
      nameConfirmed: false,
      emailVerificationCode: "",
      phoneNumber: "",
      password: "",
      confirmPassword: ""
    },
    validationSchema: step == 1 ? personalDetailSchema : validationSchema,
    onSubmit: (values) => {
      if (step == 1) {
        setStep(2)
      } else if (step == 2) {
        setBtnLoading(true)
        registeration(values)
          .then((res) => {
            setErrors({})
            setBtnLoading(false)
            setStep(3)
          })
          .catch((err) => {
            setErrors(err?.response?.data?.errors)
            setBtnLoading(false)
          })
      }
    },
  });
  function renderStep() {
    if (step == 1) {
      return <PersonalDetail registerFormik={registerFormik} />
    }
    else if (step == 2) {
      return <CompanyDetail registerFormik={registerFormik} btnLoading={btnLoading} errors={errors} />
    }
    else if (step == 3) {
      return <VerificationCode registerFormik={registerFormik} setStep={setStep} />
    }
    else if (step == 4) {
      return <AddExtraSecurity registerFormik={registerFormik} target={searchParams.get("target")} />
    }
  }

  return (
    <div className={styles.signupContent}>
      <div className={styles.leftChild}>
        <div>
          <div className={styles.header}>
            <NavLink to="/">
              <svg
                width="121"
                height="42"
                viewBox="0 0 121 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="42"
                  height="42"
                  rx="9.33333"
                  fill="url(#paint0_linear_1_16515)"
                  className="white-bg"
                />
                <rect
                  x="0.466667"
                  y="0.466667"
                  width="41.0667"
                  height="41.0667"
                  rx="8.86667"
                  stroke="white"
                  strokeOpacity="0.13"
                  strokeWidth="0.933333"
                />
                <path
                  d="M21.2553 29.2066L25.7001 33H16.8105L21.2553 29.2066Z"
                  fill="white"
                  className="hover"
                />
                <path
                  d="M22.22 22.964C22.2242 24.255 21.7662 25.5097 20.9199 26.526C20.0735 27.5423 18.8881 28.261 17.5545 28.5662C16.221 28.8714 14.8171 28.7454 13.569 28.2085C12.3209 27.6716 11.3013 26.7551 10.6745 25.6066C10.0477 24.4581 9.85016 23.1445 10.1138 21.8775C10.3774 20.6105 11.0868 19.4639 12.1277 18.6225C13.1686 17.781 14.4803 17.2937 15.8515 17.2391C17.2227 17.1844 18.5735 17.5657 19.6863 18.3213L18.3273 20.0922C17.6374 19.6237 16.7999 19.3873 15.9497 19.4212C15.0996 19.4551 14.2863 19.7572 13.641 20.2789C12.9956 20.8006 12.5558 21.5115 12.3924 22.297C12.2289 23.0826 12.3514 23.897 12.74 24.6091C13.1286 25.3211 13.7608 25.8894 14.5346 26.2223C15.3084 26.5552 16.1788 26.6333 17.0056 26.444C17.8324 26.2548 18.5674 25.8092 19.0921 25.1791C19.6169 24.549 19.9008 23.7711 19.8982 22.9706L22.22 22.964Z"
                  fill="white"
                  className="hover"
                />
                <path
                  d="M15.2471 15.8855C15.024 14.8465 15.1107 13.7697 15.4976 12.7741C15.8845 11.7786 16.5565 10.9031 17.4394 10.2444C18.3223 9.58573 19.3815 9.16954 20.5002 9.04185C21.6188 8.91417 22.7531 9.07996 23.7778 9.52091C24.8025 9.96185 25.6777 10.6608 26.3064 11.5404C26.9352 12.42 27.2932 13.446 27.3407 14.5051C27.3883 15.5641 27.1236 16.6149 26.5759 17.5413C26.0282 18.4676 25.2188 19.2334 24.2373 19.7541L23.0969 17.8516C23.7054 17.5288 24.2072 17.054 24.5468 16.4797C24.8864 15.9053 25.0505 15.2538 25.021 14.5972C24.9915 13.9406 24.7696 13.3045 24.3797 12.7591C23.9899 12.2138 23.4473 11.7804 22.812 11.5071C22.1767 11.2337 21.4734 11.1309 20.7798 11.21C20.0863 11.2892 19.4295 11.5472 18.8822 11.9556C18.3348 12.364 17.9181 12.9068 17.6783 13.5241C17.4384 14.1413 17.3846 14.8089 17.5229 15.4531L15.2471 15.8855Z"
                  fill="white"
                  className="hover"
                />
                <path
                  d="M22.3324 18.2913C23.2485 17.6741 24.3282 17.3072 25.4521 17.2312C26.576 17.1553 27.7004 17.3732 28.7009 17.8608C29.7014 18.3484 30.539 19.0869 31.1212 19.9944C31.7035 20.9019 32.0075 21.9432 31.9999 23.0032C31.9922 24.0632 31.6731 25.1005 31.0778 26.0005C30.4825 26.9005 29.6342 27.6281 28.6267 28.1029C27.6193 28.5777 26.4919 28.7811 25.3691 28.6908C24.2464 28.6004 23.1721 28.2197 22.265 27.5909L23.6425 25.8327C24.2049 26.2226 24.871 26.4586 25.5671 26.5146C26.2631 26.5706 26.9621 26.4445 27.5868 26.1501C28.2114 25.8558 28.7373 25.4047 29.1064 24.8467C29.4755 24.2887 29.6734 23.6455 29.6781 22.9883C29.6829 22.3312 29.4943 21.6855 29.1334 21.1229C28.7724 20.5602 28.2531 20.1024 27.6327 19.8C27.0124 19.4977 26.3153 19.3626 25.6185 19.4097C24.9217 19.4568 24.2522 19.6843 23.6843 20.0669L22.3324 18.2913Z"
                  fill="white"
                  className="hover"
                />
                <path
                  d="M54 29.4835V12.4435H60.384C62.464 12.4435 64.04 12.8195 65.112 13.5715C66.2 14.3075 66.744 15.4675 66.744 17.0515C66.744 18.0595 66.448 18.8835 65.856 19.5235C65.264 20.1635 64.44 20.5475 63.384 20.6755C64.68 20.8035 65.68 21.2355 66.384 21.9715C67.104 22.6915 67.464 23.6355 67.464 24.8035C67.464 26.3715 66.92 27.5475 65.832 28.3315C64.76 29.0995 63.232 29.4835 61.248 29.4835H54ZM57.12 19.5955H60.288C61.312 19.5955 62.112 19.4035 62.688 19.0195C63.264 18.6355 63.552 18.0755 63.552 17.3395C63.552 16.5715 63.272 16.0035 62.712 15.6355C62.152 15.2675 61.344 15.0835 60.288 15.0835H57.12V19.5955ZM57.12 26.8435H61.104C62.08 26.8435 62.848 26.6435 63.408 26.2435C63.984 25.8435 64.272 25.2515 64.272 24.4675C64.272 23.6835 63.984 23.0835 63.408 22.6675C62.848 22.2515 62.08 22.0435 61.104 22.0435H57.12V26.8435Z"
                  fill="white"
                />
                <path
                  d="M69.4875 29.4835V16.6675H72.5595V29.4835H69.4875ZM69.4395 14.9635V13L72.6315 12.2275V14.9635H69.4395Z"
                  fill="white"
                />
                <path
                  d="M79.3119 29.4835C78.0319 29.4835 77.0879 29.1955 76.4799 28.6195C75.8879 28.0275 75.5919 27.0915 75.5919 25.8115V19.0435H73.5759V16.6675H75.5919V13.6675H78.6639V16.6675H82.0479V19.0435H78.6639V25.5235C78.6639 26.1315 78.7919 26.5475 79.0479 26.7715C79.3199 26.9955 79.7199 27.1075 80.2479 27.1075H82.0479V29.4835H79.3119Z"
                  fill="white"
                />
                <path
                  d="M86.577 29.4835C85.633 29.4835 84.881 29.2435 84.321 28.7635C83.777 28.2835 83.505 27.5155 83.505 26.4595V12.4435H86.577V26.1475C86.577 26.7875 86.897 27.1075 87.537 27.1075H88.473V29.4835H86.577Z"
                  fill="white"
                />
                <path
                  d="M90.0684 29.4835V16.6675H93.1404V29.4835H90.0684ZM90.0204 14.9635V13L93.2124 12.2275V14.9635H90.0204Z"
                  fill="white"
                />
                <path
                  d="M95.2491 29.4835V16.6675H98.0331L98.1051 18.8515C98.4571 17.9715 98.9691 17.3395 99.6411 16.9555C100.329 16.5715 101.097 16.3795 101.945 16.3795C103.353 16.3795 104.433 16.8355 105.185 17.7475C105.937 18.6435 106.313 19.8115 106.313 21.2515V29.4835H103.241V22.2355C103.241 21.1475 103.073 20.3155 102.737 19.7395C102.417 19.1635 101.841 18.8755 101.009 18.8755C100.177 18.8755 99.5211 19.1635 99.0411 19.7395C98.5611 20.3155 98.3211 21.1475 98.3211 22.2355V29.4835H95.2491Z"
                  fill="white"
                />
                <path
                  d="M113.99 29.7715C112.71 29.7715 111.598 29.4995 110.654 28.9555C109.726 28.3955 108.998 27.6115 108.47 26.6035C107.958 25.5955 107.702 24.4195 107.702 23.0755C107.702 21.7315 107.958 20.5555 108.47 19.5475C108.982 18.5395 109.702 17.7635 110.63 17.2195C111.574 16.6595 112.67 16.3795 113.918 16.3795C115.15 16.3795 116.222 16.6515 117.134 17.1955C118.046 17.7395 118.75 18.5235 119.246 19.5475C119.758 20.5715 120.014 21.8035 120.014 23.2435V23.9395H110.894C110.942 25.0595 111.246 25.9075 111.806 26.4835C112.366 27.0435 113.102 27.3235 114.014 27.3235C115.406 27.3235 116.294 26.7315 116.678 25.5475L119.822 25.7395C119.47 26.9875 118.774 27.9715 117.734 28.6915C116.71 29.4115 115.462 29.7715 113.99 29.7715ZM110.894 21.8755H116.822C116.758 20.8355 116.462 20.0675 115.934 19.5715C115.406 19.0595 114.734 18.8035 113.918 18.8035C113.086 18.8035 112.406 19.0675 111.878 19.5955C111.35 20.1235 111.022 20.8835 110.894 21.8755Z"
                  fill="white"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1_16515"
                    x1="42"
                    y1="42"
                    x2="-378.5"
                    y2="-288"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#4556F3" />
                    <stop offset="1" stopColor="white" />
                  </linearGradient>
                </defs>
              </svg>
            </NavLink>
          </div>
          <div className={styles.customStepper}>
            <div className={`${styles.step} ${step > 1 && styles.completed} ${step == 1 && styles.active}`}>
              <div className={styles.circle}></div>
              <div className={styles.info}>
                <h5>Personal details</h5>
                <p>Please provide your name</p>
              </div>
            </div>
            <div className={`${styles.step} ${step > 2 && styles.completed} ${step == 2 && styles.active}`}>
              <div className={styles.circle}></div>
              <div className={styles.info}>
                <h5>Your details</h5>
                <p>Please provide your email and phone number</p>
              </div>
            </div>
            <div className={`${styles.step} ${step > 3 && styles.completed} ${step == 3 && styles.active}`}>
              <div className={styles.circle}></div>
              <div className={styles.info}>
                <h5>Verification</h5>
                <p>Please verify your email</p>
              </div>
            </div>
            <div className={`${styles.step} ${step > 4 && styles.completed} ${step == 4 && styles.active}`}>
              <div className={styles.circle}></div>
              <div className={styles.info}>
                <h5>Add Extra Security</h5>
                <p>Secure your auth by adding two step verification</p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <p>© Bitline 2024</p>
          <p>
            <svg
              width="16"
              height="12"
              viewBox="0 0 16 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.3335 2.6665L6.77678 6.4768C7.21756 6.78535 7.43795 6.93962 7.67767 6.99938C7.88943 7.05216 8.1109 7.05216 8.32265 6.99938C8.56238 6.93962 8.78277 6.78535 9.22355 6.4768L14.6668 2.6665M4.5335 11.3332H11.4668C12.5869 11.3332 13.147 11.3332 13.5748 11.1152C13.9511 10.9234 14.2571 10.6175 14.4488 10.2412C14.6668 9.81333 14.6668 9.25328 14.6668 8.13317V3.8665C14.6668 2.7464 14.6668 2.18635 14.4488 1.75852C14.2571 1.3822 13.9511 1.07624 13.5748 0.884491C13.147 0.666504 12.5869 0.666504 11.4668 0.666504H4.5335C3.41339 0.666504 2.85334 0.666504 2.42552 0.884491C2.04919 1.07624 1.74323 1.3822 1.55148 1.75852C1.3335 2.18635 1.3335 2.7464 1.3335 3.8665V8.13317C1.3335 9.25328 1.3335 9.81333 1.55148 10.2412C1.74323 10.6175 2.04919 10.9234 2.42552 11.1152C2.85334 11.3332 3.41339 11.3332 4.5335 11.3332Z"
                stroke="#94969C"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            help@bitline.com
          </p>
        </div>
      </div>
      <div className={styles.rightChild}>
        {renderStep()}
        <div className={styles.footer}>
          <p>© Bitline 2024</p>
          <p>
            <svg
              width="16"
              height="12"
              viewBox="0 0 16 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.3335 2.6665L6.77678 6.4768C7.21756 6.78535 7.43795 6.93962 7.67767 6.99938C7.88943 7.05216 8.1109 7.05216 8.32265 6.99938C8.56238 6.93962 8.78277 6.78535 9.22355 6.4768L14.6668 2.6665M4.5335 11.3332H11.4668C12.5869 11.3332 13.147 11.3332 13.5748 11.1152C13.9511 10.9234 14.2571 10.6175 14.4488 10.2412C14.6668 9.81333 14.6668 9.25328 14.6668 8.13317V3.8665C14.6668 2.7464 14.6668 2.18635 14.4488 1.75852C14.2571 1.3822 13.9511 1.07624 13.5748 0.884491C13.147 0.666504 12.5869 0.666504 11.4668 0.666504H4.5335C3.41339 0.666504 2.85334 0.666504 2.42552 0.884491C2.04919 1.07624 1.74323 1.3822 1.55148 1.75852C1.3335 2.18635 1.3335 2.7464 1.3335 3.8665V8.13317C1.3335 9.25328 1.3335 9.81333 1.55148 10.2412C1.74323 10.6175 2.04919 10.9234 2.42552 11.1152C2.85334 11.3332 3.41339 11.3332 4.5335 11.3332Z"
                stroke="#94969C"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            help@bitline.com
          </p>
        </div>
      </div>
    </div>
  );
};
function mapStateToProps({ user }) {
  return { user }
}
export default connect(mapStateToProps, userActions)(SignupWrapper);

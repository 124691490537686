import React, { useEffect, useState } from "react";
import "./Media.scss";
import PrimaryButton from "../../../../common/primaryButton";
import Tag from "../../../../components/tags/Tag";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../../../../common/ScrollToTop";
import { getArticles } from "../../../../services/blogs";
import { blogsConfig } from "../../../../config/config";
import moment from "moment";

const Media = () => {
  const [getBlogs, setGetBlogs] = useState([]);
  const [currentPage] = useState(1);
  const navigate = useNavigate();
  const navigateToSignup = () => {
    navigate("/signup");
    scrollToTop();
  };

  useEffect(() => {
    GetArticleListing(currentPage);
  }, [currentPage]);

  const GetArticleListing = async () => {
    const { status, res } = await getArticles(1, 4);
    if (status) {
      setGetBlogs(res.data);
    }
  };

  return (
    <section className="section media-section" id="mediaId">
      <div className="container">
        <div className="header">
          <div className="left" data-aos="fade-right">
            <Tag
              dot
              text="Media"
              className="stroke"
              style={{ marginInline: "0" }}
            />
            <h2>Highlighted blogs and resources</h2>
          </div>
          <div className="right" data-aos="fade-left">
            <PrimaryButton
              handleClick={navigateToSignup}
              primaryBtnText="Join Bitline"
              className="secondary"
              primaryBtnIcon={
                <svg
                  width="7"
                  height="10"
                  viewBox="0 0 7 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.43994 0.760254L5.43994 4.76025L1.43994 8.76025"
                    stroke="black"
                    strokeWidth="1.36"
                  />
                </svg>
              }
            />
          </div>
        </div>
        {getBlogs.map((item, index) => (
          <div
            key={index}
            className="blog"
            data-aos="fade-up"
            data-aos-delay={`${index * 200}`}
          >
            <div className="blog-text" data-aos="fade-right">
              <div className="blog-tag">
                <span>
                  {item.attributes?.fintech_category?.data?.attributes?.name}
                </span>
              </div>
              <h4>{item?.attributes?.title}</h4>
              <p>{item?.attributes?.description}</p>
              <PrimaryButton
                handleClick={() => navigate(`/blog-detail/${item.id}`)}
                primaryBtnText="Read more"
                className="secondary"
                primaryBtnIcon={
                  <svg
                    width="7"
                    height="10"
                    viewBox="0 0 7 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.43994 0.760254L5.43994 4.76025L1.43994 8.76025"
                      stroke="black"
                      strokeWidth="1.36"
                    />
                  </svg>
                }
              />
            </div>
            <div className="blog-info" data-aos="fade-left">
              <div className="blog-tag">
                <span>
                  {moment(item?.attributes?.publishedAt).format("DD MMM YYYY")}
                </span>
              </div>
              <div className="blog-user">
                <div className="img">
                  <img
                    src={`${blogsConfig.imgBaseUrl}${
                      item?.attributes?.creator?.data?.attributes?.creator_image
                        ?.data?.attributes?.url ||
                      `${blogsConfig.imgBaseUrl}/uploads/creator_logo_50e0b92492.png`
                    }`}
                    alt="user-img"
                  />
                </div>
                <div className="user">
                  <h5>
                    {item?.attributes?.creator?.data?.attributes?.creator_name}
                  </h5>
                  <p>Product, Developer Platform</p>
                </div>
              </div>
              <div className="blog-img">
                <img
                  src={`${blogsConfig.imgBaseUrl}${item?.attributes?.display_image?.data?.attributes?.url}`}
                  alt="blog-img"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Media;

import React from "react";
import "./style.scss";
import { btnSpinner } from "../../assets/images/images";

const PrimaryButton = ({
  aos,
  aosDelay,
  type,
  style,
  noIcon,
  className,
  isLoading,
  handleClick,
  primaryBtnIcon,
  primaryBtnText,
  primaryExternalLink,
  disabled,
}) => {
  const handleExternalClick = () => {
    window.open(primaryExternalLink, "_blank");
  };
  return (
    <button
      data-aos={aos}
      data-aos-delay={aosDelay}
      style={{ ...style, pointerEvents: isLoading ? "none" : "auto" }}
      type={type || "button"}
      disabled={disabled}
      className={`primary-button ${className ? className : ""}`}
      data-text={isLoading ? "" : primaryBtnText}
      onClick={() => {
        if (handleClick) {
          handleClick?.();
        } else if (primaryExternalLink) {
          handleExternalClick();
        }
      }}
    >
      {isLoading ? (
        <div className="spinner-overlay">
          <div className="spinner"></div>
        </div>
      ) : (
        <span>
          {primaryBtnText} {noIcon ? "" : primaryBtnIcon}
        </span>
      )}
    </button>
  );
};
export default PrimaryButton;

import React from "react";
import "./SaleAgreement.scss";
import { NavLink } from "react-router-dom";

const SaleAgreement = () => {
  return (
    <section className="section terms-hero">
      <div className="container">
        <div className="content">
          <h2>Customer Conditional Sale Agreement</h2>
        </div>
        <div className="desc">
          <h5>INTRODUCTION</h5>
          <h4>Terms of Service</h4>
          <p>
            Welcome to Bitline. Our Digital Asset Custody and Sale Account
            (“Digital Account”) allows You to deposit digital assets with
            Bitline with the option to lock some or all of these assets into a
            sale agreement (“Sale Agreement”) (which may be voided under certain
            conditions) to access a portion of their value at participating
            vendor locations. . Bitline will provide a secure custody
            environment for digital asset safekeeping and provide a list of
            participating vendors for the Client to designate.
            <br />
            <br />
            Please read and keep these Digital Account Terms. If You need a
            physical copy of these Digital Account Terms or if You have any
            questions, You can contact Us via email at support@Bitline.io or
            visit Bitline.com. These Digital Account Terms are incorporated by
            reference into the Bitline Terms of Service found here:{" "}
            <NavLink to={"/terms-of-use"}>
              https://bitline.io/terms-of-use/
            </NavLink>
          </p>
          <br />
          <h4>OUR RELATIONSHIP</h4>
          <p>
            In these Digital Account Terms, holders of a Digital Account are
            sometimes referred to in the Digital Account Terms as “You” or
            “Your” or the “ Client” or the “Customer. The provider of the
            Digital Account, Ibanera LLC, is referred to as “Bitline,” “We,”
            “Us” or “Our” in these Digital Account Terms. Together, You and We
            may be collectively referred to as “Parties.”
          </p>
          <br />
          <h4>ADDITIONAL DEFINITIONS</h4>
          <p>
            <b>"Additional Assets"</b> shall mean Digital Assets, Cash, or
            equivalents added to the Deposited Assets amount.
          </p>
          <p>
            <b>"Additional Assets Threshold"</b> shall mean an amount equal to
            one hundred thirty percent (130%) of the Requested Amount.
          </p>
          <p>
            <b>"Approved Currency"</b> shall mean the applicable Fiat Currency.
          </p>
          <p>
            <b>"Assets"</b> shall mean the (i) Digital Assets deposited with the
            Custodian, including, without limitation, any Digital Assets
            deposited as Additional Assets; (ii) any New Tokens issued with
            respect to any Digital Assets under clause (i) following a Hard
            Fork; (iii) all private keys associated with the foregoing; (iv) all
            Cash and equivalents , including any Cash constituting Additional
            Assets; (v) any replacements thereof; (v) all books and records
            relating thereto; and (vi) all proceeds thereof.
          </p>
          <p>
            <b>"Business Day"</b> shall mean any day other than a Saturday,
            Sunday or a banking holiday in the United States of America.
          </p>
          <p>
            <b>"Calendar Day"</b> shall mean each and every day of the week.
          </p>
          <p>
            <b>"Cashed Deposited Assets Account"</b> shall mean
            ___________________
          </p>
          <p>
            <b>"Conversion Rate"</b> shall mean any conversion between a Digital
            Asset and Fiat Currency made by Bitline in accordance with the rates
            and prices applicable at the actual time of conversion. Applicable
            rates are indexed to those used by industry-leading platforms, as
            Bitline may choose to use from time to time, in its sole discretion.
            Parties may currently use rates provided and determined by Our
            liquidity providers. Bitline may change these rate sources at any
            time and without giving prior notice or updating these Digital
            Account Terms, and Customer shall not have any claims regarding its
            choice of rate sources or rates made available by any third party.
          </p>
          <p>
            <b>"Custodial Agreement"</b> shall mean an agreement for custodial
            services, executed by Bitline, Customer and Custodian, in form and
            substance satisfactory to Bitline.
          </p>
          <p>
            <b>"Custodian"</b> shall mean Ibanera Pte Ltd.
          </p>
          <p>
            <b>"Customer"</b> shall mean the registered owner of the Digital
            Account.
          </p>
          <p>
            <b>"Deposited Assets"</b> shall mean Digital Assets that have been
            locked into a Sales Agreement.
          </p>
          <p>
            <b>"Deposited Assets Value"</b> shall mean, at any time, the
            aggregate value, measured in Fiat Currency, of all Digital Assets
            comprising the Sale Agreement amount at the then-applicable
            Conversion Rate, as determined by Bitline in its sole discretion.
          </p>
          <p>
            <b>"Digital Asset"</b> shall mean Bitcoin (BTC), Ethereum (ETH) or
            any other cryptocurrency or other digital assets stored in the
            blockchain as may be agreed in writing between Bitline and Customer.
          </p>
          <p>
            <b>"Early Settlement Threshold"</b> shall mean an amount equal to
            one hundred twenty percent (120%) of the Requested Amount.
          </p>
          <p>
            <b>"Effective Date"</b> shall mean the date upon which Digital
            Assets that have been locked into a Sales Agreement .
          </p>
          <p>
            <b>"Fiat Currency"</b> shall mean a government-issued currency that
            is not backed by a physical commodity, such as gold or silver, for
            example the U.S. dollar, and which is agreed upon by the parties as
            the applicable fiat currency in the Addendum to this Agreement.
          </p>
          <p>
            <b>"Hard Fork"</b> means a permanent divergence in the blockchain,
            which commonly occurs when non-upgraded nodes cannot validate blocks
            created by upgraded nodes that follow newer consensus rules, or an
            airdrop or any other event which results in the creation of a new
            token.
          </p>
          <p>
            <b>"Maturity Date"</b> shall mean the date that is thirty (30) days
            (ten (10) days for UK based transactions) days following the
            Effective Date.
          </p>
          <p>
            <b>"Merchant"</b> shall mean Vendors supporting the Product.
          </p>
          <p>
            <b>"Minimum Sold Asset Amount"</b> means an amount equal to two (2)
            times the Requested Amount.
          </p>
          <p>
            <b>"New Tokens"</b> shall mean any tokens representing any piece of
            Deposited Assets that are created as a result of a Hard Fork.
          </p>
          <p>
            <b>"Product"</b> shall mean the Sale Agreement entered into between
            Customer and Bitline via the Online Platform and the resulting
            Deposit and Customer access to funds in the Requested Amount
            (defined below) to be available to Customer at participating vendor
            locations.
          </p>
          <p>
            <b>"Requested Amount"</b> shall mean amount of funds or value
            Customer wishes to have available at a merchant or other
            participating vendor locations.
          </p>
          <p>
            <b>"Return of Deposited Assets"</b> shall mean the delivery by
            Custodian to Customer of all Digital Assets constituting Deposited
            Assets, together with all private keys associated therewith [in
            accordance with the Custodial Agreement].
          </p>
          <p>
            <b>"Sanctions"</b> shall mean sanctions administered or enforced by
            the U.S. Department of the Treasury’s Office of Foreign Assets
            Control (commonly known as “OFAC”), the U.S. Department of State,
            the United Nations Security Council, or other relevant sanctions
            authority.
          </p>
          <p>
            <b>"Settlement Date"</b> shall mean the date upon which any
            Settlement Event occurs.
          </p>
          <p>
            <b>"Solvent"</b> shall mean with respect to any person on any date
            of determination, that on such date (1) the fair value of the
            property of such person is greater than the total amount of
            liabilities, including contingent liabilities, of such person; (2)
            the present fair salable value of the assets of such person is not
            less than the amount that will be required to pay the probable
            liability of such person on its debts as they become absolute and
            matured; (3) such person does not intend to, and does not believe
            that it will, incur debts or liabilities beyond such Person’s
            ability to pay such debts and liabilities as they mature; (4) such
            person is not engaged in business or a transaction, and is not about
            to engage in business or a transaction, for which such person’s
            property would constitute an unreasonably small capital; and (5)
            such person is able to pay its debts and liabilities, contingent
            obligations and other commitments as they mature in the ordinary
            course of business.
          </p>
          <br />
          <h4>ACCEPTANCE OF TERMS</h4>
          <p>
            By opening a Digital Account, You agree that the terms and
            conditions contained herein will govern Your Digital Account. Any
            Digital Account will be governed by these Digital Account Terms, as
            modified from time to time. We reserve the right to update these
            Digital Account Terms at any time and may notify You of such changes
            via the Website or by email at the address specified on Your account
            application, but We have no obligation to notify You of every
            update.
          </p>
          <br />
          <ul className="dot-list">
            <li>Opening a Digital Account</li>
            <li>
              Digital Accounts can be opened at Bitline.io, the Bitline app. All
              Digital Assets received will initially be deposited into an
              account with the Custodian. Digital Accounts are only available in
              those jurisdictions approved by Bitline. UInited States based
              clients must be twenty-one (21) years of age or older to open a
              Digital Account. Other jurisdictions outside of the United States
              require clients to be eighteen (18) years of age or older to open
              a digital account.
            </li>
            <li>
              In order to open Your Digital Account, Bitline will require You to
              provide acceptable forms of identification. To help the government
              fight the funding of terrorism and money laundering activities,
              United States federal law requires Us to obtain, verify, and
              record information that identifies each person or entity that
              opens a Digital Account. We may also ask for Your driver's license
              or other identifying documents. You represent and warrant that any
              and all information provided to Us pursuant to these Digital
              Account Terms or otherwise is true, accurate and not misleading in
              any respect. If any such information changes, it is Your
              obligation to update such information as soon as possible. Note,
              that by applying for a Digital Account You agree and consent that
              that We may use credit reports or other information from third
              parties to help Us determine if We should open or maintain Your
              account.
            </li>
            <li>
              Digital Accounts are not available in Cuba, Iran, North Korea,
              Sudan, Syria, or any other country to which the United States
              embargoes goods or imposes similar sanctions. Digital Accounts are
              not available in the State of New York, or any other jurisdiction
              which We determine We would not be able to or chose not to offer
              Digital Accounts. Due to the dynamic nature of regulatory
              requirements and interpretations in the cryptocurrency market, We
              reserve the right to update the list of prohibited jurisdictions
              and reject applications to open Digital Accounts that We determine
              We would be unable to accept for regulatory or policy reasons in
              Our sole and absolute discretion.
            </li>
            <li>
              Your Digital Account is not deemed to be opened, and We have no
              responsibility to You unless and until You have received written
              (which may be electronic) confirmation from Us that Your Digital
              Account has been opened. We are not obligated to accept an
              application from any applicant, and We reserve the right not to
              open a Digital Account for any applicant in Our sole and absolute
              discretion.
            </li>
            <li>Funding Your Digital Account</li>
            <li>
              You can open Your Digital Account by transferring eligible Digital
              Assets to the wallet address provided in Your Bitline Digital
              Account . Your Digital Assets will be accepted in Custody by
              Ibanera Pte Ltd LLC.
            </li>
            <li>
              The transfer of such eligible Digital Assets to Your Digital
              Account may not be deemed settled and completed until the
              transaction has met the specific protocol for a relevant
              cryptocurrency as determined by Bitline.
            </li>
          </ul>
          <br />
          <h4>Issuance of the Product.</h4>
          <p>
            Once the funding of Your Digital Account is complete You may enter
            into a voidable Sale Agreement with the Custodian in order to
            facilitate deposit of Digital Assets and receipt of funds in the
            Requested Amount at the merchant, financial services business, or
            legal gaming establishment..
          </p>
          <br />
          <h4>Fees and Voiding the Sale</h4>
          <p>
            As consideration for Bitline’s services hereunder, Customer shall
            pay a fee equal to ten percent (10%) of the Requested Amount (the “
            <b>Service Fee</b>”). The Service Fee shall be immediately and fully
            earned on the Effective Date, and shall be payable on the
            Termination Date. The Service Fee shall be nonrefundable. All
            payments of the Service fee shall be made without deduction or
            withholding for any taxes.
          </p>
          <br />
          <p>
            <b>For US transactions</b>: In the event that the Customer
            terminates this agreement prior to the Maturity Date (and so long as
            no other Termination Event has then occurred) and voids the sale
            pursuant to the terms of the Sale Agreement , the Customer shall be
            entitled to a discount as follows: (i) if termination occurs on or
            before the seventh (7th) day following the Effective Date, fifty
            percent (50%) of the amount of the Service Fee and (ii) if
            termination occurs after the seventh (7th) day and on or before the
            fourteenth (14th) day following the Effective Date, twenty-five
            percent (25%) of the amount of the Service Fee. There shall be no
            discount available against the Service Fee after the fourteenth
            (14th) day following the Effective Date.
          </p>
          <br />
          <p>
            <b>For UK transactions</b>: In the event that the Customer
            terminates this agreement prior to the Maturity Date (and so long as
            no other Termination Event has then occurred) and voids the sale
            pursuant to the terms of the Sale Agreement, Customer shall be
            entitled to a discount as follows: if termination occurs on or
            before the fifth (5th ) day following the Effective Date, fifty
            percent (50%) of the amount of the Service Fee. There shall be no
            discount available against the Service Fee after the fifth (5th) day
            following the Effective Date.
          </p>
          <br />
          <h4>Deposit of Deposited Assets; Grant of Security Interest .</h4>
          <p>
            With each Sale Agreement, the Customer shall deliver via the Bitline
            Online Platform to the Custodian, Deposited Assets as of the time of
            transfer to the Custodian of not less than the Minimum Sold Asset
            Amount.
          </p>
          <p>
            By its execution of this Agreement, the Customer hereby assigns and
            transfers to Bitline, pending fulfillment of the terms of the Sales
            Agreement , all rights, title, and interest whether now owned or at
            any time hereafter acquired the Deposited Assets for the prompt and
            complete payment and performance when due (whether at the stated
            maturity, by acceleration or otherwise) of its obligations hereunder
            and in connection with the Product.
          </p>
          <p>
            The Custodian shall hold the Deposited Assets in a
            non-interest-bearing account, for the benefit of Bitline. Following
            deposit of the Deposited Assets with the Custodian, the Customer
            shall have no rights or ability to direct the use or disposition of
            the Deposited Assets, or to otherwise direct the Custodian with
            respect to the Deposited Assets prior to a Return of Deposited
            Assets.
          </p>
          <p>
            Bitline shall be deemed to have exercised reasonable care in the
            custody and preservation of the Deposited Assets in its possession
            if the Deposited Assets is accorded treatment substantially equal to
            that which it accords its own property.
          </p>
          <p>
            Bitline shall not be liable or responsible for any loss or damage to
            any of the Deposited Assets, or for any diminution in the value
            thereof, by reason of the act or omission of Custodian, or any
            warehouseman, carrier, forwarding agency, consignee or other agent
            or bailee reasonably selected by Agent in good faith. It is
            expressly agreed that Bitline shall have no responsibility for (i)
            ascertaining or taking action with respect to calls, conversions,
            exchanges, maturities, tenders or other matters relative to any
            Deposited Assets, whether or not Bitline has or is deemed to have
            knowledge of such matters, or (ii) taking any necessary steps to
            preserve rights against any parties with respect to any Deposited
            Assets, but Bitline may do so and all expenses incurred in
            connection therewith shall be payable by and for the sole account of
            the Customer.
          </p>
          <p>
            The Customer shall from time to time, at his or her expense,
            promptly execute and deliver all further instruments, documents and
            notices and take all further action that may be necessary, or that
            Bitline may request, in order to perfect the Sale Agreement and
            interests granted herein, or to enable Bitline to exercise and
            enforce its rights and remedies hereunder with respect to the
            Deposited Assets. Without limiting the generality of the foregoing,
            the Customer shall, upon Bitline’s request, appear in and defend any
            action or proceeding that may affect the Customer’s title to or
            Bitline’s interest in the Deposited Assets.
          </p>
          <br />
          <h4>Settlement Event; Cash Deposited Assets.</h4>
          <p>
            The Sale Agreement shall remain outstanding until the earliest to
            occur of (i) the Maturity Date, (ii) termination of the Sale
            Agreement by Customer pursuant to Cash Redemption as detailed below,
            (iii) an Event of Default hereunder and (iv) an Early Settlement
            Event (each, a “<b>Settlement Event</b>”). Upon the occurrence of a
            Settlement Event, the Sale Agreement shall immediately terminate and
            Bitline shall have the immediate and irrevocable right to notify
            Merchant of such termination.
          </p>
          <br />
          <h4>Cash Redemption.</h4>
          <p>
            The Customer shall have the right at any time to replace the
            Deposited Assets with cash in an Approved Currency in an amount
            equal to one hundred ten percent (110%) of (i) the Deposited Assets
            plus (ii) the Service Fee (such amounts, “<b>Cash Redemption</b>”),
            which will be deposited to the Deposited Assets Account with the
            Custodian. Following any Deposited Asset Cash Redemption and
            confirmation by Custodian of receipt and acknowledgment of the Cash
            Redemption in the Cash Deposited Assets Account, Bitline shall
            instruct the Custodian to effect a Return of Deposited Assets.
          </p>
          <br />
          <h4>Deposited Assets Value; Early Settlement; Additional Assets.</h4>
          <p>
            The Customer shall ensure that the Deposited Assets Value of the
            Deposited Assets at all times exceeds the Early Settlement
            Threshold. The first failure to do so shall immediately cause an “
            <b>Early Settlement Event</b>”.
          </p>
          <p>
            If at any time the Deposited Assets Value is less than or equal to
            the Additional Assets Threshold, the Customer shall provide
            Additional ______________ with a Deposited Assets Value sufficient
            to take the Deposited Assets Value, measured as of the time of
            deposit, of all Deposited Assets to an amount equal to the
            Additional Assets Threshold, unless otherwise agreed by Bitline in
            writing. Bitline shall use commercially reasonable efforts to
            provide notice to Customer that the Deposited Assets Value has
            fallen to or below the Additional Assets Threshold. Customers shall
            have until the earliest to occur of (i) [twelve (12) hours]
            following the time such notice is sent by Bitline and (ii) an Early
            Settlement Event to provide the Additional Deposited Assets. Failure
            to provide Additional Deposited Assets within the time period
            specified shall constitute an immediate Early Settlement Event.
            Customer hereby consents to messages by electronic mail, SMS, text
            and/or messaging through Merchant’s platform, and hereby
            acknowledges that any of the foregoing shall constitute delivery of
            notice hereunder. Any failure to provide notice, or any failure by
            Customer to receive or review any such notice shall not in any way
            prejudice Bitline’s rights hereunder.
          </p>
          <p>
            Upon the occurrence of a Settlement Event, to the extent that the
            Customer has not previously (i) paid to Bitline in cash in an
            Approved Currency an amount sufficient to pay in full all amounts
            drawn on the Product plus the Service Fee, Bitline shall have the
            immediate and irrevocable right to direct the Custodian to liquidate
            and dispose of the Deposited Assets and convert the Deposited Assets
            into an Approved Currency, without notice to or the consent of the
            Customer.
          </p>
          <br />
          <h4>Settlement; Liquidation of Deposited Assets.</h4>
          <p>
            Any sale or other disposition of Deposited Assets following a
            Settlement Event may be carried out by Bitline, the Custodian or any
            of their respective agents, professionals or designees (each a “
            <b>Sale Party</b>” and collectively, the “<b> Sale Parties</b>”), in
            one or more transactions, whether through a public or private sale,
            for such price or prices and upon such other terms as the Sale
            Parties may deem commercially reasonable, in their sole discretion.
            No Sale Party shall have any duty or responsibility to maximize the
            value of the Deposited Assets, nor incur any liability to Customer
            on that basis. The Sale Parties shall have no obligation to marshal
            any Deposited Assets. All proceeds of any disposition of the
            Deposited Assets shall be placed in a non-interest bearing account
            (the “<b>Cash Deposited Assets Account</b>”) at the Custodian, in
            the name of Bitline.
          </p>
          <br />
          <p>
            On or before the [5th] day of the first month following the
            Settlement Event, Bitline shall instruct the Custodian to remit to
            the Customer the balance of the Cash Deposited Assets, less an
            amount equal to (i) the Service Fee; (ii) the balance of any amounts
            drawn on the Product by the Merchant; (iii) the amount of all fees,
            costs and expenses of Bitline or any Sale Party in connection with
            the sale or disposition of the Deposited Assets, or as otherwise
            reimbursable hereunder (the “<b> Return Amount</b>”). All taxes and
            other charges (including, without limitation, any income tax or
            sales charges) in connection with the sale or disposition of the
            Deposited Assets shall be borne by and before the account of the
            Customer. Neither Bitline nor the Custodian shall have any liability
            for such amounts, and shall have no duty, express or implied, to
            remit such amounts to any relevant authorities. All other amounts in
            the Cash Deposited Assets Account shall thereafter irrevocably be
            the property of Bitline, for its own account and as payment for
            services hereunder.
          </p>
          <p>
            In the event that the amounts in the Cash Deposited Assets Account
            are insufficient to pay all amounts due to Bitline under this
            Agreement, Customer agrees to pay the full amount of any such
            overage, in cash, within two (2) Business Days following demand
            therefor. In connection with the foregoing, Customer hereby
            irrevocably appoints Bitline and the other Sale Parties, with full
            power of substitution, as Customer’s attorney-in-fact, with full
            power following a Settlement Event to take any and all actions with
            respect to the Deposited Assets, to execute and endorse any
            instruments of transfer on behalf of the Customer, whether in the
            Customer’s name or such Sale Party’s own name, and to do all things
            necessary to carry out the terms of Customer’s Sale Agreement.
            Following the occurrence of a Settlement Event, Bitline is hereby
            authorized by the Customer at any time or from time to time, without
            notice or demand, to set off and to appropriate and to apply any and
            all (i) balances in the Cash Deposited Assets Account or (ii) other
            property, including any Deposited Assets, held or owing by or on
            behalf of Bitline to or for the credit or for the account of the
            Customer, against and on account of any amounts due to Bitline
            hereunder. The Customer agrees that Bitline or any of its affiliates
            may, to the fullest extent permitted by law, exercise Bitline’s
            right to setoff with respect to the amounts due to Bitline
            hereunder. Bitline’s rights under this Section are in addition to
            any other rights and remedies (including any other rights of setoff)
            that Bitline or any of its affiliates may have at law or in equity.
          </p>
          <br />

          <h4>REPRESENTATIONS AND WARRANTIES</h4>
          <p>
            Customer’s Representations and Warranties . The Customer hereby
            represents and warrants to Bitline as follows:
          </p>
          <br />
          <ul className="dot-list">
            <li>
              The Customer has full power and authority to enter into this Sale
              Agreement. Customer’s execution of, entry into and performance
              under this Agreement (i) does not violate any law, rule or
              regulation applicable to the Customer, (ii) does not violate or
              contravene any contract or agreement to which the Customer is a
              party or which affects the Customer’s property (including, without
              limitation, the Deposited Assets), (iii) does not violate any
              order, writ, judgment, award, injunction or decree binding on or
              affecting the Customer’s property (including, without limitation,
              the Deposited Assets), and (iv) does not result in or require the
              creation of any lien on or security interest in any of the
              Customer’s property (including, without limitation, the Deposited
              Assets), other than the commitments and obligations created
              hereunder.
            </li>
            <li>
              This Sale Agreement constitutes a legal, valid and binding
              obligation of the Customer, enforceable against the Customer in
              accordance with its terms, except as limited by bankruptcy,
              insolvency, moratorium, fraudulent conveyance or other laws
              relating to the enforcement of creditors’ rights generally and
              general principles of equity (regardless of whether enforcement is
              sought at equity or law).
            </li>
            <li>
              The Customer is the legal and beneficial owner of the Deposited
              Assets and upon execution of this Sale Agreement and the deposit
              of the Deposited Assets with the Custodian (including, without
              limitation, any Cash Deposited Assets or proceeds of disposition
              of Deposited Assets into the Cash Deposited Assets Account). The
              Deposited Assets will be free and clear of any lien, security
              interest or other encumbrance. No effective financing statement or
              other instrument similar in effect covering any Deposited Assets
              is on file in any recording office and no competing notice or
              notice inconsistent with the transactions contemplated by this
              Sale Agreement has been sent to the Customer.
            </li>
            <li>
              Before and after giving effect to the transactions contemplated by
              this Sale Agreement, the Customer is and was Solvent and has not
              incurred debts or liabilities beyond the Customer’s ability to
              pay. The commitments granted hereunder on the Deposited Assets has
              been made in good faith and without intent to hinder, delay, or
              defraud any of the Customer’s present or future creditors.
            </li>
            <li>
              Neither Customer nor any of Customer’s family or affiliates is a
              person that is, or is owned or controlled by persons that are (a)
              the subject or target of any Sanctions, (b) located, organized or
              resident in a country or territory that is, or whose government
              is, the subject of Sanctions.
            </li>
            <li>
              The Customer has not been under administrative, civil, or criminal
              investigation, indictment, information, suspension, debarment, or
              audit (other than a routine contract audit) by any party, under
              internal investigation by any party, or the subject of any inquiry
              or allegations of any kind involving fraud, corruption, money
              laundering, or criminal misconduct; nor are such investigations or
              inquiries threatened or pending. The Customer has not received
              notice, inquiry, or other communication from, or made a voluntary
              disclosure to, the U.S. Department of Justice or other criminal,
              civil or administrative enforcement agency of any domestic or
              non-U.S. jurisdiction in connection with alleged or possible
              fraud, corruption, or criminal misconduct. The Customer has not
              been convicted of or pled guilty to an offense involving fraud,
              corruption, or criminal misconduct.
            </li>
            <li>
              The Customer is entering into this Sale Agreement for the
              Customer’s own account, not as nominee or agent.
            </li>
            <li>
              The Customer has such knowledge, skill and experience in business,
              financial and investment matters that the Customer is capable of
              evaluating the merits and risks of the transactions contemplated
              by this Sale Agreement. With the assistance of the Customer’s own
              professional advisors, to the extent that the undersigned has
              deemed appropriate, the undersigned has made its own legal, tax,
              accounting and financial evaluation of the merits and risks of the
              consequences of this Sale Agreement.
            </li>
          </ul>
          <br />
          <p>
            Bitline’s Representations and Warranties. Bitline hereby represents
            and warrants to the Customer as follows:
          </p>
          <ul className="dot-list">
            <li>
              Bitline has full power and authority to enter into this Sale
              Agreement. Bitline’s execution of, entry into and performance
              under this Agreement (i) does not violate any law, rule or
              regulation applicable to the Customer, and (ii) does not violate
              or contravene any contract or agreement to which the Customer is a
              party or which affects the Customer’s property (including, without
              limitation, the Deposited Assets).
            </li>
            <li>
              This Agreement constitutes a legal, valid and binding obligation
              of Bitline, enforceable against Bitline in accordance with its
              terms, except as limited by bankruptcy, insolvency, moratorium,
              fraudulent conveyance or other laws relating to the enforcement of
              creditors’ rights generally and general principles of equity
              (regardless of whether enforcement is sought at equity or law).
            </li>
          </ul>
          <br />
          <h4>HARD FORKS</h4>
          <p>
            The Customer shall provide written notice to Bitline promptly
            following the occurrence of a Hard Fork affecting the Deposited
            Assets. Upon receipt of such notice, or if Bitline otherwise becomes
            aware of or gains knowledge of a Hard Fork affecting the Deposited
            Assets, Bitline shall have the right, at its sole option, to:
          </p>
          <ul className="dot-list">
            <li>
              require that the Customer immediately transfer any New Tokens to
              Custodian as Deposited Assets;
            </li>
            <li>
              authorize and direct the Custodian to take such actions to acquire
              any New Tokens representing Digital Assets constituting Deposited
              Assets.
            </li>
          </ul>
          <p>
            In connection with the foregoing, Customer hereby irrevocably
            appoints Bitline and the Custodian, with full power of substitution,
            as Customer’s attorney-in-fact, with full power following a Hard
            Fork to take any and all actions with respect to the Deposited
            Assets, including, without limitation, effectuate the issuance of
            New Tokens, and to execute and endorse any instruments of transfer
            on behalf of the Customer, whether in the Customer’s name or such
            Sale Party’s own name, and to do all things necessary to carry out
            the terms of this Sale Agreement.
          </p>
          <br />

          <h4>EVENT OF DEFAULT</h4>
          <p>
            The occurrence of any of the following shall constitute an “
            <b>Event of Default</b> ”:
          </p>
          <ul className="dot-list">
            <li>
              Any representation or warranty of the Customer was not at the time
              made, or shall hereafter cease to be true and correct in all
              respects;
            </li>
            <li>
              The Customer shall in any way, directly or indirectly, repudiate
              or challenge the validity, or support any other party in
              repudiating or challenging the validity, of this Sale Agreement or
              the Custodian’s interests in the Deposited Assets;
            </li>
            <li>
              The Customer shall default in the performance or observance of any
              provision of this Sale Agreement; or
            </li>
            <li>
              The Customer shall generally not pay its debts as such debts
              become due, or shall admit in writing its inability to pay the
              Customer’s debts generally, shall cease to be Solvent or shall
              make a general assignment for the benefit of creditors; or any
              proceeding shall be instituted by or against the Customer seeking
              to adjudicate it as bankrupt or insolvent, or seeking liquidation,
              winding up, reorganization, arrangement, adjustment, protection,
              relief, or composition of it or its debts under any law relating
              to bankruptcy, insolvency or reorganization or relief of debtors,
              or seeking the entry of an order for relief or the appointment of
              a receiver, trustee, custodian or other similar official for it or
              for any substantial part of its property, or any of the actions
              sought in such proceeding (including, without limitation, the
              entry of an order for relief, or the appointment of a receiver,
              trustee, custodian or other similar official for it or for any
              substantial part of its Property) shall occur or such Party shall
              acquiesce to or fail to object to such relief; or any Party shall
              take any action to institute, initiate or authorize any of the
              actions set forth above in this Section.
            </li>
          </ul>
          <br />
          <h4>ASSIGNMENT</h4>
          <p>
            Neither party may assign, transfer, mortgage, charge, create a trust
            over, subcontract or deal in any other manner with any of its
            rights, benefits and obligations under this Agreement (or purport to
            do so) without the prior written consent of the other party, which,
            in the case of an assignment by Bitline, shall not be unreasonably
            withheld or delayed; provided, that Bitline may transfer its rights
            and obligations hereunder to (i) any affiliated entity or (ii) to
            any entity acquiring all of the stock or all or substantially all of
            Bitline’s assets without the consent of the Customer.
          </p>
          <br />
        </div>
      </div>
    </section>
  );
};

export default SaleAgreement;

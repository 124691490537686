import React from "react";
import "./Hero.scss";
import Tag from "../../../../components/tags/Tag";
import PrimaryButton from "../../../../common/primaryButton";
import FeaturedSlider from "../slider/FeaturedSlider";
import { useNavigate } from "react-router-dom";
import { heroVideo } from "../../../../assets/videos/videos";

const Hero = () => {
  const navigate = useNavigate();
  const navigateToRegister = () => {
    navigate("/signup");
  };
  return (
    <section className="hero-section">
      <div className="hero-img">
        {/* <img src={heroImg} alt="hero-img" /> */}
        <video autoPlay muted loop playsInline data-aos="fade-right">
          <source src={heroVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="container">
        <div className="hero-content" data-aos="fade-right">
          <Tag text="Powerful, smart and worldwide available" />
          <h1>Casino transactions via crypto assets</h1>
          <p>
            Get cash or chips in just minutes at casinos around the world at any
            time!
          </p>
          <PrimaryButton
            primaryBtnText="Join Bitline"
            handleClick={navigateToRegister}
            className="secondary"
            primaryBtnIcon={
              <svg
                width="7"
                height="10"
                viewBox="0 0 7 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.43994 0.760254L5.43994 4.76025L1.43994 8.76025"
                  stroke="black"
                  strokeWidth="1.36"
                />
              </svg>
            }
          />
        </div>
        <FeaturedSlider />
      </div>
    </section>
  );
};

export default Hero;

const config = {
  base_url: process.env.REACT_APP_BASE_URL,
  base_url_website: process.env.REACT_APP_BASE_URL_WEBSITE,
  base_url_venue: process.env.REACT_APP_BASE_URL_VENUE,
  base_url_admin: process.env.REACT_APP_BASE_URL_ADMIN,

  //website
  website_url_member: process.env.REACT_APP_WEBSITE_URL_MEMBER,
  website_url_venue: process.env.REACT_APP_WEBSITE_URL_VENUE,
  website_url_admin: process.env.REACT_APP_WEBSITE_URL_ADMIN,
};

export default config;

export let blogsConfig = {
  baseUrl: process.env.REACT_APP_BLOG_BASE_URL,
  imgBaseUrl: process.env.REACT_APP_BLOG_IMAGE_BASE_URL,
};

import React from "react";
import { accordion } from "./helper";
import { Accordion, AccordionItem } from "@szhsin/react-accordion";
import styles from "./faq.module.scss";
import PrimaryButton from "../../../../common/primaryButton";
import Tag from "../../../../components/tags/Tag";
import { scrollToTop } from "../../../../common/ScrollToTop";
import { useNavigate } from "react-router-dom";

const Faq = () => {
  const navigate = useNavigate();
  const navigateToContact = () => {
    navigate("/contact-us");
    scrollToTop();
  };
  return (
    <section className={`section ${styles.faqSection}`} id="faqId">
      <div className="container">
        <div className={styles.heading} data-aos="fade-up">
          <Tag dot text="FAQs" className="stroke" />
          <h3>Everything you need to know</h3>
          <p>
            We facilitate cryptocurrency owners direct access to liquidity in
            just minutes using their digital assets without creating taxable
            events
          </p>
          <PrimaryButton
            handleClick={navigateToContact}
            primaryBtnText="Contact support"
            className="secondary"
            style={{ width: "fit-content", marginInline: "auto" }}
            primaryBtnIcon={
              <svg
                width="7"
                height="10"
                viewBox="0 0 7 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.43994 0.760254L5.43994 4.76025L1.43994 8.76025"
                  stroke="black"
                  strokeWidth="1.36"
                />
              </svg>
            }
          />
        </div>
        <div data-aos="fade-up">
          <Accordion transition transitionTimeout={250}>
            {accordion.map(({ accordionHeading, accordionBody, index }, i) => (
              <AccordionItem
                header={accordionHeading}
                key={i}
                initialEntered={i === 0}
              >
                <span>{accordionBody}</span>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </div>
    </section>
  );
};

export default Faq;

import React from "react";
import "./HowItWorks.scss";
import Tag from "../../../../components/tags/Tag";
import PrimaryButton from "../../../../common/primaryButton";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../../../../common/ScrollToTop";

const HowItWorks = () => {
  const navigate = useNavigate();
  const navigateToSignup = () => {
    navigate("/signup");
    scrollToTop();
  };
  return (
    <section className="section works-section" id="howItWorksId">
      <div className="container">
        <div className="header">
          <div className="left" data-aos="fade-right">
            <Tag
              dot
              text="How it works"
              className="stroke"
              style={{ marginInline: "0" }}
            />
            <h2>How Bitline Works</h2>
          </div>
          <div className="right" data-aos="fade-left">
            <PrimaryButton
              handleClick={navigateToSignup}
              primaryBtnText="Join Bitline"
              className="secondary"
              primaryBtnIcon={
                <svg
                  width="7"
                  height="10"
                  viewBox="0 0 7 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.43994 0.760254L5.43994 4.76025L1.43994 8.76025"
                    stroke="black"
                    strokeWidth="1.36"
                  />
                </svg>
              }
            />
          </div>
        </div>
        <div className="videos-content">
          <iframe
            data-aos="fade-right"
            src="https://www.youtube.com/embed/2GE-1xic4as"
            title="How To Use the BitLine App"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
          <iframe
            data-aos="fade-left"
            src="https://www.youtube.com/embed/oWs_wzLKTcw"
            title="HOW TO CREATE A GUARANTEE ON BITLINE"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>
        <p data-aos="fade-up">
          Bitline allows you to create transactions in minutes using your
          digital assets. These transactions can be used to access funds quickly
          with participating casinos around the world.
        </p>
      </div>
    </section>
  );
};

export default HowItWorks;

import React from "react";
import Hero from "./sections/hero/Hero";
import Faq from "./sections/faq/Faq";
import Solution from "./sections/solution/Solution";
import HowItWorks from "./sections/howItWorks/HowItWorks";
import CaseStudy from "./sections/caseStudy/CaseStudy";
import Media from "./sections/media/Media";
import Contact from "../../components/contact/Contact";
import Product from "./sections/product/Product";

const Home = () => {
  return (
    <>
      <Hero />
      <Solution />
      <Product />
      <HowItWorks />
      <CaseStudy />
      <Media />
      <Faq />
      <Contact />
    </>
  );
};

export default Home;

import axios from "axios";
import config from "./config"
const Bitline = axios.create({
    baseURL: config.base_url,
    headers: {
        'Access-Control-Allow-Origin': '*'
    }
})

export default Bitline

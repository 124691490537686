import React from "react";
import "./style.scss";
import { loginVideo } from "../../assets/videos/videos";

const LoginWrapper = ({ children }) => {
  return (
    <div className="auth-login">
      {children}
      <div className="right">
        <video autoPlay muted loop playsInline data-aos="fade-right">
          <source src={loginVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        {/* <img src={loginImg} alt="login-img" /> */}
      </div>
    </div>
  );
};

export default LoginWrapper;

import {combineReducers,compose,createStore,applyMiddleware} from "redux";
import {thunk} from "redux-thunk";
import user from "./reducers/user/user";
const reducers =combineReducers({
    user
})


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers,{},composeEnhancers(applyMiddleware(thunk)));
export {store}
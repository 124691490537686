import React, { useState, useEffect } from "react";

export default function Resend({ onResend }) {
    const [disable, setDisable] = useState(false);
    const [timer, setTimer] = useState(0); // Timer in seconds

    function onResendFire() {
        if (!disable) {
            onResend()
                .then(() => {
                    setDisable(true);
                    setTimer(120); // Set timer to 120 seconds (2 minutes)
                })
                .catch((err) => console.error("Error:", err));
        }
    }

    useEffect(() => {
        let interval;
        if (disable && timer > 0) {
            interval = setInterval(() => {
                setTimer((prev) => prev - 1);
            }, 1000);
        }
        if (timer === 0) {
            setDisable(false);
        }
        return () => clearInterval(interval);
    }, [disable, timer]);

    // Format timer as mm:ss
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    return (
        <span
            style={{
                borderBottom: disable ? "none" : "1px solid #94969c", // Hide underline for timer
            }}
            onClick={onResendFire}>
            {disable ? `Resend in ${formatTime(timer)}` : "Click to resend."}
        </span>
    );
}

import React from "react";
import "./GetStarted.scss";
import PrimaryButton from "../../../common/primaryButton";

const GetStarted = () => {
  return (
    <section className="section get-started-section" data-aos="fade-up">
      <div className="content">
        <h2>Can’t see a position that suits you?</h2>
        <p>
          We’re always looking for smart, motivated people. Send us your CV and
          let’s have a chat. We’d love to meet you!
        </p>
        <PrimaryButton primaryBtnText="Get started" />
      </div>
    </section>
  );
};

export default GetStarted;

import styles from "./faq.module.scss";

export const accordion = [
  {
    accordionHeading:
      "What happens to my cryptocurrency when I send it to Bitline?",
    accordionBody:
      "When you create a Bitline account, you get a personal cryptocurrency wallet on our secure platform. Any crypto you send to your Bitline wallet is stored safely, and you have full control to transfer funds to and from your wallet at any time.",
  },
  {
    accordionHeading:
      "What happens when I make a transaction with a participating Casino?",
    accordionBody:
      "When you initiate a transaction with a Casino, your Bitline wallet sends funds to a Bitline corporate wallet. Once the Casino chips are issued, they’re securely held in the corporate wallet until you close the transaction.",
  },
  {
    accordionHeading:
      "Why doesn’t my cryptocurrency show up in my Bitline account right away?",
    accordionBody:
      "Transfers can take time depending on the speed of the Bitcoin, Ethereum, or Stablecoin networks. Blockchain demand can delay transactions, but once confirmed, your funds will appear in your Bitline wallet immediately. We provide an estimated completion time based on current network speeds.",
  },
  {
    accordionHeading:
      "How do I get my Casino Chips after making a transaction?",
    accordionBody:
      "After your transaction is approved by the Casino, you’ll receive a QR code in the Bitline App. Take it to the Casino cashier, who will verify your identity and issue your chips.",
  },
  {
    accordionHeading:
      "How do I close my Bitline.io transaction your Casino if I win or lose?",
    accordionBody: (
      <>
        Your Bitline.io transactions can be closed in a couple of ways: <br />
        <ul className={styles.list}>
          <li>
            You can simply close the transaction on your own by selecting the
            unlock transaction function in the Bitline.io App. This will allow
            you to pay the amount of Casino funds you received either by using
            your cryptocurrency assets, or by any other payment method available
            to you in the Bitline App.
          </li>
          <li>
            For each transaction that you create, there is a 30 day maximum
            timeframe for that transaction to be closed. If you do not close a
            transaction by initiating a payment via the Bitline.io website, then
            your transaction will automatically close at the end of the
            aforementioned 30 day period initiated at the creation for that
            specific transaction.
          </li>
        </ul>
      </>
    ),
    // "You can close your transaction through the Bitline App by selecting the 'unlock' option and paying using your cryptocurrency or another available payment method. Alternatively, visit the Casino cashier with your unique QR code to settle the transaction. Each transaction must be closed within 30 days.",
  },
  {
    accordionHeading:
      "What if my cryptocurrency value changes during an active transaction?",
    accordionBody:
      "The value of your crypto may fluctuate, but the USD value of your transaction is locked in at the time you initiate it. If the value of your crypto changes, you'll still maintain the same number of Casino chips. If the value drops significantly, you may be prompted to add funds to keep your transaction value intact. You can also choose to pay with a different payment method when closing the transaction.",
  },
];

import { LOG_IN, LOG_OUT } from '../../actions/types';

const initialState = {
};

export default function user(state = initialState, action) {
    switch (action.type) {
        case LOG_IN:
            return action.payload;
        case LOG_OUT:
            return action.payload
        default:
            return state;
    }
}
import React from "react";
import "./Career.scss";
import JobCard from "./jobCard/JobCard";
import GetStarted from "./getStarted/GetStarted";
import Contact from "../../components/contact/Contact";

const Career = () => {
  return (
    <section className="section career-section">
      <div className="container">
        <div className="heading" data-aos="fade-up">
          <h2>Join the Bitline team today.</h2>
          <p>
            If you are passionate about the worlds of Cryptocurrency and the
            Fintech Payments Industry
          </p>
        </div>
      </div>
      <JobCard />
      <GetStarted />
      <Contact />
    </section>
  );
};

export default Career;

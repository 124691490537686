import React, { useState } from "react";
import "./Contact.scss";
import Tag from "../tags/Tag";
import ContactCard from "./card/ContactCard";
import { demo, message } from "../../assets/images/images";
import { footerVideo } from "../../assets/videos/videos";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { countries } from "./coutries";
import PrimaryButton from "../../common/primaryButton";

const Contact = ({ noMessage }) => {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  return (
    <>
      <section
        className={`section contact-section ${noMessage ? "contact-page" : ""}`}
      >
        <div className="contact-video-bg">
          <video autoPlay muted loop playsInline data-aos="fade-right">
            <source src={footerVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="container">
          <div className="heading">
            <Tag dot text="Contact us" className="stroke" />
            <h3>We're here to help</h3>
            <p>
              Get in touch with our sales and support teams for product
              questions, live architecture sessions, demos and more.
            </p>
          </div>
          <div className="cards">
            <ContactCard
              noMessage
              icon={demo}
              title="Schedule a Demo"
              desc="Talk to an expert about your data security needs. Discuss your requirements, learn about custom pricing, or request a product demo."
              linkText="Book a demo"
              demo
              handleClick={onOpenModal}
            />
            {!noMessage ? (
              <ContactCard
                icon={message}
                title="Send us a message"
                desc="Have a question? Get hands on support directly from our engineers by sending us a message. We'll respond to you in <12 hours."
                linkText="Get in touch"
                linkUrl="/contact-us"
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </section>
      <Modal
        classNames={{
          modal: "modal-class",
        }}
        open={open}
        onClose={onCloseModal}
        center
      >
        <div className="header">
          <h3>Book a Demo</h3>
          <p>
            Can't find what you're looking for? Please contact us and we'll get
            back to you as soon as possible.
          </p>
        </div>
        <form>
          <div className="input">
            <input type="text" name="name" placeholder="Name" />
          </div>

          <div className="input">
            <input type="email" name="email" placeholder="Email address" />
          </div>
          <div className="input">
            <input type="phone" name="phone" placeholder="Phone number" />
          </div>
          <div className="input">
            <select name="country">
              <option value="">Select your country</option>
              {countries.map((country, index) => (
                <option key={index} value={country}>
                  {country}
                </option>
              ))}
            </select>
          </div>
          <div className="input">
            <textarea
              name="message"
              id=""
              rows={8}
              placeholder="Additional notes or questions"
            />
          </div>
          <PrimaryButton
            primaryBtnText="Submit"
            // className="dark"
            type="submit"
          />
        </form>
      </Modal>
    </>
  );
};

export default Contact;

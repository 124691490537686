import React from "react";
import "./Solution.scss";
import Tag from "../../../../components/tags/Tag";
import PrimaryButton from "../../../../common/primaryButton";
import {
  caseBitline,
  companyOverView,
  missionStatement,
} from "../../../../assets/images/images";
import { coinVideo } from "../../../../assets/videos/videos";
import { scrollToTop } from "../../../../common/ScrollToTop";
import { useNavigate } from "react-router-dom";

const Solution = () => {
  const navigate = useNavigate();
  const navigateToWebRegister = () => {
    scrollToTop();
    navigate("/signup");
  };
  return (
    <section className="section solution-section" id="aboutUs">
      <div className="container">
        <div className="header">
          <div className="text-content" data-aos="fade-right">
            <Tag
              dot
              text="Solution"
              className="stroke"
              style={{ marginInline: "0" }}
            />
            <h2>
              We’re the only provider for casino chip access directly from
              cryptocurrency
            </h2>
            <p>
              We facilitate cryptocurrency owners direct access to liquidity in
              just minutes using their digital assets without creating taxable
              events. Bitline`s cryptocurrency platform can be used in Casinos
              around the world.
            </p>
            <PrimaryButton
              handleClick={navigateToWebRegister}
              primaryBtnText="Join Bitline"
              className="secondary"
              primaryBtnIcon={
                <svg
                  width="7"
                  height="10"
                  viewBox="0 0 7 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.43994 0.760254L5.43994 4.76025L1.43994 8.76025"
                    stroke="black"
                    strokeWidth="1.36"
                  />
                </svg>
              }
            />
          </div>
          <div className="vid-content" data-aos="fade-left">
            {/* <img src={solutionImg} alt="solution-img" /> */}
            <video autoPlay muted loop playsInline data-aos="fade-right">
              <source src={coinVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>

        <div className="solution-content">
          <div className="col" data-aos="fade-up" data-aos-delay="100">
            <div className="icon">
              <img src={missionStatement} alt="mission-icon" />
            </div>
            <h4>Mission Statement</h4>
            <p>
              Payments provider with a mission to deliver fast, secure,
              compliant, and “24/7/365” access to funds...
            </p>
            <a href="#!">
              Learn more
              <span>
                <svg
                  width="7"
                  height="10"
                  viewBox="0 0 7 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.43994 0.760254L5.43994 4.76025L1.43994 8.76025"
                    stroke="white"
                    strokeWidth="1.36"
                  />
                </svg>
              </span>
            </a>
          </div>
          <div className="col" data-aos="fade-up" data-aos-delay="200">
            <div className="icon">
              <img src={companyOverView} alt="company-overview" />
            </div>
            <h4>Company Overview</h4>
            <p>
              Bitline is a mobile application designed to bring liquidity to a
              market that is largely untouched today...
            </p>
            <a href="#!">
              Learn more
              <span>
                <svg
                  width="7"
                  height="10"
                  viewBox="0 0 7 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.43994 0.760254L5.43994 4.76025L1.43994 8.76025"
                    stroke="white"
                    strokeWidth="1.36"
                  />
                </svg>
              </span>
            </a>
          </div>
          <div className="col" data-aos="fade-up" data-aos-delay="300">
            <div className="icon">
              <img src={caseBitline} alt="case-bitline" />
            </div>
            <h4>A Case For Bitline</h4>
            <p>
              Rather than attempting to only solve part of the problem, Bitline
              has deployed industrial-grade tools...
            </p>
            <a href="#!">
              Learn more
              <span>
                <svg
                  width="7"
                  height="10"
                  viewBox="0 0 7 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.43994 0.760254L5.43994 4.76025L1.43994 8.76025"
                    stroke="white"
                    strokeWidth="1.36"
                  />
                </svg>
              </span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Solution;
